import { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Canvas, useFrame } from '@react-three/fiber'
import { useSpring, a } from '@react-spring/three'

const Letter = ({ active, index, name, position, onLoad }) => {
  // Animate if hovered
  const loading = useRef(true)
  const meshRef = useRef(null)
  const primitiveRef = useRef(null)
  const [rot, setRot] = useState([0, 0, 0])

  // Load models
  const [obj, setObj] = useState(null)
  useMemo(() => {
    if (!loading.current) return
    new OBJLoader().load(`${process.env.PUBLIC_URL}/obj/${name}.obj`, model => {
      // Update base material of each mesh
      const mat = new THREE.MeshLambertMaterial({
        color: 'white',
        opacity: 0,
        transparent: true
      })
      model.traverse(m => {
        if (!m.material) return
        m.material = mat
      })
      loading.current = false
      // Set model in state
      setObj(model)
      onLoad()
    })
  }, [name, onLoad])

  // Move up and down
  useFrame(({ clock }) => {
    if (!meshRef.current) return
    const t = clock.getElapsedTime()
    // Undulate!
    const stepDiff = 0.4 * (index + 1)
    const mesh = meshRef.current
    mesh.position.y = Math.sin(t * 4 + stepDiff) * 0.2
    // Fade in after load
    const primitive = primitiveRef.current
    primitive &&
      primitive.traverse(m => {
        if (m.material && m.material.opacity < 1) {
          m.material.opacity += 0.03
        }
      })
  })

  // Flip after time
  useEffect(() => {
    if (!active) return
    const timeout = setTimeout(() => {
      setRot([
        THREE.MathUtils.degToRad(THREE.MathUtils.radToDeg(rot[0]) + 360),
        0,
        0
      ])
    }, 5000 + Math.random() * 1000)
    return () => clearTimeout(timeout)
  }, [rot, active])

  const { scale, rotation } = useSpring({
    to: { scale: [1, 1, 1], rotation: rot }
  })

  return (
    <a.mesh
      ref={meshRef}
      // onPointerOver={e => {
      //   e.stopPropagation()
      //   setActive(true)
      // }}
      // onPointerOut={() => setActive(false)}
      position={position}
      rotation={rotation}
      scale={scale}
    >
      {obj && <primitive ref={primitiveRef} object={obj} />}
    </a.mesh>
  )
}

const Logo = ({ active, loading, theme, mouse, onLoad }) => {
  // Load each letter then tell parent we're ready
  const loadCount = useRef(0)
  const onLetterLoad = useCallback(() => {
    loadCount.current++
    if (loadCount.current > 3) {
      onLoad && onLoad()
    }
  }, [loadCount, onLoad])

  // Spring movement
  const { scale, rotation, lightPos } = useSpring({
    from: { scale: [0, 0, 0], rotation: [0, 0, 0], lightPos: [0, 0, 2] },
    to: {
      scale: [1, 1, 1],
      rotation: [
        THREE.MathUtils.degToRad(mouse[1] * 22.5),
        THREE.MathUtils.degToRad(mouse[0] * 22.5),
        0
      ],
      lightPos: [mouse[0] * 4, mouse[1] * -4, 2]
    }
  })

  return (
    <div className='logo'>
      <Canvas
        camera={{
          fov: 30,
          position: [0, 0, 8],
          near: 0.1,
          far: 20000
        }}
      >
        {/* Lights */}
        <a.pointLight
          position={lightPos}
          color={theme.primary}
          intensity={1}
          angle={0.2}
        />
        <pointLight
          position={[-3, 0, 0]}
          color={theme.secondary}
          intensity={1}
        />
        <pointLight
          position={[0, 3, 0]}
          color={theme.secondary}
          intensity={1}
        />
        <pointLight
          position={[0, -3, 0]}
          color={theme.tertiary}
          intensity={1}
        />

        {/* Letters */}
        <a.group rotation={rotation} scale={scale}>
          <Letter
            active={active}
            index={0}
            name='v'
            position={[-2.096, 0, 0.5]}
            loading={loading}
            onLoad={onLetterLoad}
          />
          <Letter
            active={active}
            index={1}
            name='o'
            position={[-0.681, 0, 0.5]}
            loading={loading}
            onLoad={onLetterLoad}
          />
          <Letter
            active={active}
            index={2}
            name='l'
            position={[0.878, 0, 0.5]}
            loading={loading}
            onLoad={onLetterLoad}
          />
          <Letter
            active={active}
            index={3}
            name='t'
            position={[2.096, 0, 0.5]}
            loading={loading}
            onLoad={onLetterLoad}
          />
        </a.group>
      </Canvas>
    </div>
  )
}

export default Logo
