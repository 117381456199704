import { useCallback, useEffect, useState } from 'react'
import Image from '../../../components/Image'
import Svg from '../../../components/Svg'
import Video from '../../../components/Video'
import RichText from '../../../components/RichText'

const loadingDelay = 1000

const Hero = ({ data }) => {
  const [loading, setLoading] = useState('start')
  const { icon, title, body, background, overlay, image, video } = data

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading === 'start') {
        setLoading('wait')
      }
    }, loadingDelay)
    return () => clearTimeout(timeout)
  }, [loading])

  const handleLoad = useCallback(() => {
    setLoading('end')
  }, [])

  return (
    <div className='hero'>
      {/* Background */}
      {background && (
        <div className='background' style={{ background: background.style }}>
          {background.video ? (
            <Video
              fill
              id={background.video}
              params={{ width: 1920, height: 1080 }}
              onLoad={handleLoad}
            />
          ) : (
            <Image
              fill
              id={background.image}
              params={{ width: 1920, height: 1080 }}
              onLoad={handleLoad}
            />
          )}
        </div>
      )}

      {/* Image / Video */}
      {(image || video) && (
        <div className='inner'>
          {video ? (
            <Video
              id={video}
              params={{ width: 1920, height: 1080, fit: 'inside' }}
              onLoad={handleLoad}
            />
          ) : (
            <Image
              id={image}
              params={{ width: 1920, height: 1080, fit: 'inside' }}
              onLoad={handleLoad}
            />
          )}
        </div>
      )}

      {/* Text and icon */}
      <div className={overlay ? 'text overlay' : 'text'}>
        {overlay && (
          <div
            className='overlay-gradient'
            style={
              overlay
                ? {
                    background: overlay
                  }
                : null
            }
          />
        )}
        <div className='text-inner'>
          <Svg name={icon} />
          <h1>{title}</h1>
          <h4 className='long'>
            <RichText>{body}</RichText>
          </h4>
        </div>
      </div>

      {/* Loading state */}
      <div className='loading'>
        <div className={`icon${loading === 'wait' ? ' active' : ''}`}>
          <Svg name='video' />
        </div>
      </div>
    </div>
  )
}

export default Hero
