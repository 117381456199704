const PrivacyScreen = () => {
  return (
    <div className='container'>
      {/* Intro */}
      <h1>Privacy Policy</h1>
      <p>
        Thank you for choosing to be part of our community at Volt Group Ltd
        ("Company," "we," "us," or "our"). We are committed to protecting your
        personal information and your right to privacy. If you have any
        questions or concerns about this privacy notice or our practices with
        regard to your personal information, please contact us at
        <a href='mailto:info@volt.co.uk'> info@volt.co.uk</a>.
      </p>
      <p>
        This privacy notice describes how we might use your information if you:
      </p>
      <ul>
        <li>
          Download and use our mobile application — <strong>Monarch</strong>
        </li>
        <li>
          Use our Facebook application — <strong>Monarch</strong>
        </li>
        <li>
          Engage with us in other related ways ― including any sales, marketing,
          or events
        </li>
      </ul>
      <p>In this privacy notice, if we refer to:</p>
      <ul>
        <li>
          "<strong>App</strong>," we are referring to any application of ours
          that references or links to this policy, including any listed above
        </li>
        <li>
          "<strong>Services</strong>," we are referring to our App, and other
          related services, including any sales, marketing, or events
        </li>
      </ul>
      <p>
        The purpose of this privacy notice is to explain to you in the clearest
        way possible what information we collect, how we use it, and what rights
        you have in relation to it. If there are any terms in this privacy
        notice that you do not agree with, please discontinue use of our
        Services immediately.
      </p>
      <p>
        Please read this privacy notice carefully, as it will help you
        understand what we do with the information that we collect.
      </p>

      {/* Links */}
      <ul className='links'>
        <li>
          <a href='#1'>1. What information do we collect?</a>
        </li>
        <li>
          <a href='#2'>2. How do we use your information?</a>
        </li>
        <li>
          <a href='#3'>3. Will your information be shared with anyone?</a>
        </li>
        <li>
          <a href='#4'>4. How do we handle your social logins?</a>
        </li>
        <li>
          <a href='#5'>5. How long do we keep your information?</a>
        </li>
        <li>
          <a href='#6'>6. How do we keep your information safe?</a>
        </li>
        <li>
          <a href='#7'>7. What are your privacy rights?</a>
        </li>
        <li>
          <a href='#8'>8. Controls for do-not-track features</a>
        </li>
        <li>
          <a href='#9'>9. Do we make updates to this notice?</a>
        </li>
        <li>
          <a href='#10'>10. How can you contact us about this notice?</a>
        </li>
        <li>
          <a href='#11'>
            11. How can you review, update or delete the data we collect from
            you?
          </a>
        </li>
        <hr />
        {/* Section */}
        <h3 id='1'>1. What information do we collect?</h3>
        <p>
          <strong>Personal information you disclose to us</strong>
        </p>
        <p>
          <strong>In Short:</strong> We collect personal information that you
          provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the App, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the App or otherwise when you contact us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the App, the choices you make and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> We collect
          usernames; passwords; email addresses; names; and other similar
          information.
        </p>
        <p>
          <strong>Social Media Login Data.</strong> We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, Twitter or other social media account. If
          you choose to register in this way, we will collect the information
          described in the section called "
          <a href='#4'>How do we handle your social logins?</a>" below.
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p>
          <strong>Information automatically collected</strong>
        </p>
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or browser and{' '}
        <p>
          device characteristics — is collected automatically when you visit our
          App.
        </p>
        We automatically collect certain information when you visit, use or
        navigate the App. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our App and other technical information. This information is primarily
        needed to maintain the security and operation of our App, and for our{' '}
        <p>internal analytics and reporting purposes.</p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage and performance information our servers
            automatically collect when you access or use our App and which we
            record in log files. Depending on how you interact with us, this log
            data may include your IP address, device information, browser type
            and settings and information about your activity in the App (such as
            the date/time stamps associated with your usage, pages and files
            viewed, searches and other actions you take such as which features
            you use), device event information (such as system activity, error
            reports (sometimes called 'crash dumps') and hardware settings).
          </li>
          <li>
            Device Data. We collect device data such as information about your
            computer, phone, tablet or other device you use to access the App.
            Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model Internet service provider and/or mobile carrier, operating
            system and system configuration information.
          </li>
        </ul>
        <p>
          <strong>Information collected through our App</strong>
        </p>
        <p>
          In Short: We collect information regarding your push notifications,
          Facebook permissions, when you use our App.
        </p>
        <p>If you use our App, we also collect the following information:</p>
        <ul>
          <li>
            Push Notifications. We may request to send you push notifications
            regarding your account or certain features of the App. If you wish
            to opt-out from receiving these types of communications, you may
            turn them off in your device's settings.
          </li>
          <li>
            Facebook Permissions. We by default access your{' '}
            <a href='https://www.facebook.com/about/privacy/'>Facebook</a> basic
            account information, including your name, email, gender, birthday,
            current city, and profile picture URL, as well as other information
            that you choose to make public. We may also request access to other
            permissions related to your account, such as friends, checkins, and
            likes, and you may choose to grant or deny us access to each
            individual permission. For more information regarding Facebook
            permissions, refer to the{' '}
            <a href='https://developers.facebook.com/docs/facebook-login/permissions'>
              Facebook Permissions Reference
            </a>{' '}
            page.
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our App, for troubleshooting and for our internal
          analytics and reporting purposes.
        </p>
        <hr />
        {/* Section */}
        <h3 id='2'>2. How do we use your information?</h3>
        <p>
          In Short: We process your information for purposes based on legitimate
          business interests, the fulfillment of our contract with you,
          compliance with our legal obligations, and/or your consent.
        </p>
        <p></p>
        <p>
          We use personal information collected via our App for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p></p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            To facilitate account creation and logon process. If you choose to
            link your account with us to a third-party account (such as your
            Google or Facebook account), we use the information you allowed us
            to collect from those third parties to facilitate account creation
            and logon process for the performance of the contract. See the
            section below headed "
            <a href='#4'>How do we handle your social logins?</a>" for further
            information.
          </li>
          <li>
            To post testimonials. We post testimonials on our App that may
            contain personal information. Prior to posting a testimonial, we
            will obtain your consent to use your name and the content of the
            testimonial. If you wish to update, or delete your testimonial,
            please contact us at info@volt.co.uk and be sure to include your
            name, testimonial location, and contact information.
          </li>
          <li>
            Request feedback. We may use your information to request feedback
            and to contact you about your use of our App.
          </li>
          <li>
            To enable user-to-user communications. We may use your information
            in order to enable user-to-user communications with each user's
            consent.
          </li>
          <li>
            To manage user accounts. We may use your information for the
            purposes of managing our account and keeping it in working order.
          </li>
        </ul>
        <hr />
        {/* Section */}
        <h3 id='3'>3. Will your information be shared with anyone?</h3>
        <p>
          <strong>In Short</strong>: We only share information with your
          consent, to comply with laws, to provide you with services, to protect
          your rights, or to fulfill business obligations.
        </p>
        <p>
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <ul>
          <li>
            Consent: We may process your data if you have given us specific
            consent to use your personal information for a specific purpose.
          </li>
          <li>
            Legitimate Interests: We may process your data when it is reasonably
            necessary to achieve our legitimate business interests.
          </li>
          <li>
            Performance of a Contract: Where we have entered into a contract
            with you, we may process your personal information to fulfill the
            terms of our contract.
          </li>
          <li>
            Legal Obligations: We may disclose your information where we are
            legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            Vital Interests: We may disclose your information where we believe
            it is necessary to investigate, prevent, or take action regarding
            potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal
            activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <ul>
          <li>
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
        </ul>
        <hr />
        {/* Section */}
        <h3 id='4'>4. How do we handle your social logins?</h3>
        <p>
          <strong>In Short</strong>: If you choose to register or log in to our
          services using a social media account, we may have access to certain
          information about you.
        </p>
        <p>
          Our App offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, profile picture as well as other information you choose
          to make public on such social media platform. If you login using
          Facebook, we may also request access to other permissions related to
          your account, such as your friends, check-ins, and likes, and you may
          choose to grant or deny us access to each individual permission.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant App. Please note that we do not control, and are
          not responsible for, other uses of your personal information by your
          third-party social media provider. We recommend that you review their
          privacy notice to understand how they collect, use and share your
          personal information, and how you can set your privacy preferences on
          their sites and apps.
        </p>
        <hr />
        {/* Section */}
        <h3 id='5'>5. How long do we keep your information?</h3>
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <hr />
        {/* Section */}
        <h3 id='6'>6. How do we keep your information safe?</h3>
        <p>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our App is at your
          own risk. You should only access the App within a secure environment.
        </p>
        <hr />
        {/* Section */}
        <h3 id='7'>7. What are your privacy rights?</h3>
        <p>
          In Short: In some regions, such as the European Economic Area (EEA)
          and United Kingdom (UK), you have rights that allow you greater access
          to and control over your personal information. You may review, change,
          or terminate your account at any time.
        </p>
        <p>
          In some regions (like the EEA and UK), you have certain rights under
          applicable data protection laws. These may include the right (i) to
          request access and obtain a copy of your personal information, (ii) to
          request rectification or erasure; (iii) to restrict the processing of
          your personal information; and (iv) if applicable, to data
          portability. In certain circumstances, you may also have the right to
          object to the processing of your personal information. To make such a
          request, please use the contact details provided below. We will
          consider and act upon any request in accordance with applicable data
          protection laws.
        </p>
        <p>
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal, nor will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>
        <p>
          If you are a resident in the EEA or UK and you believe we are
          unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:{` `}
          <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:{` `}
          <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at <a href='mailto:info@volt.co.uk'>info@volt.co.uk</a>.
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.
        </p>
        <p>
          Opting out of email marketing: You can unsubscribe from our marketing
          email list at any time by clicking on the unsubscribe link in the
          emails that we send or by contacting us using the details provided
          below. You will then be removed from the marketing email list —
          however, we may still communicate with you, for example to send you
          service-related emails that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes. To otherwise opt-out, you may:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <hr />
        {/* Section */}
        <h3 id='8'>8. Controls for do-not-track features</h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <hr />
        {/* Section */}
        <h3 id='9'>9. Do we make updates to this notice?</h3>
        <p>
          <strong>In Short</strong>: Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <hr />
        {/* Section */}
        <h3 id='10'>10. How can you contact us about this notice?</h3>
        <p>
          If you have questions or comments about this notice, you may email us
          at <a href='mailto:info@volt.co.uk'>info@volt.co.uk</a> or by post to:
        </p>
        <p>
          Volt Group Ltd
          <br />
          230 Atkinson Road
          <br />
          Titirangi
          <br />
          Auckland
          <br />
          0604
          <br />
          New Zealand
          <br />
        </p>
        <hr />
        {/* Section */}
        <h3 id='11'>
          11. How can you review, update or delete the data we collect from you?
        </h3>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          email: <a href='mailto:info@volt.co.uk'>info@volt.co.uk</a>.
        </p>
      </ul>
    </div>
  )
}

export default PrivacyScreen
