import { useRef } from 'react'
import { Group, Row, Col } from '../../../components/Page'
import { animRiseStyles, getColors } from '../../../helpers/layoutHelper'
import { useScrolled } from '../../../helpers/interactionHelper'

const processes = [
  {
    title: `Identify`,
    description: `Build an understanding of the business and user needs, competitors and related trends. Use this to frame design challenges.`,
    items: [
      `Kick-off workshops`,
      `Stakeholder interviews`,
      `Competitor & lateral analysis`,
      `User personas & stories`,
      `Jobs theory`
    ]
  },
  {
    title: `Create`,
    description: `Explore a broad range of potential solutions to the challenge, engaging with stakeholders and wider teams for feedback and ideas.`,
    items: [
      `Team sketching sessions`,
      `Journey mapping`,
      `Wireframing`,
      `Visual design`,
      `Prototyping (Lo & Hi-Fi)`
    ]
  },
  {
    title: `Learn`,
    description: `Validate the most promissing concepts by prototyping and sharing them with real people, in face-to-face sessions and wider closed betas.`,
    items: [
      `Planning & hypothesizing`,
      `Conducting testing sessions`,
      `Summarising learnings`,
      `AB testing`,
      `Metrics analysis`
    ]
  },
  {
    title: `Evolve`,
    description: `Use testing and business feedback to further develop and refine concepts into comprehensive solutions to the challenge.`,
    items: [
      `Design evolution`,
      `Technical audit`,
      `Animation & polish`,
      `Accessibility`,
      `Project summaries`
    ]
  },
  {
    title: `Launch`,
    description: `Work with development teams and the wider business to implement and deliver finalised design thinking.`,
    items: [
      `Design specs & assets`,
      `Scripts and time savers`,
      `Collaborative coding`,
      `Front end handover`,
      `Analyse & improve`
    ]
  }
]

const Process = ({ colors }) => {
  const divRef = useRef(null)
  const scrolled = useScrolled(divRef)
  const processColors = getColors(processes.length, colors)
  return (
    <Group>
      <div ref={divRef} />
      <Row className='center'>
        <Col>
          <h2>Process</h2>
          <h4 className='long'>
            I know when (and when not) to integrate design process, here's the
            general process I like to run:
          </h4>
        </Col>
      </Row>
      <Row className='process'>
        {processes.map((item, index) => {
          const { title, description, items } = item
          const isLast = index > processes.length - 2
          const color = processColors[index]
          return (
            <Col key={index} style={animRiseStyles(scrolled, index * 100)}>
              {!isLast && <div className='line' />}
              <h4 style={{ borderColor: color }}>{title}</h4>
              <p>
                <small>{description}</small>
              </p>
              {items && (
                <ul>
                  {items.map((lItem, lIndex) => (
                    <li key={lIndex}>
                      <span className='dot' style={{ color }}>
                        •
                      </span>
                      {lItem}
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          )
        })}
      </Row>
    </Group>
  )
}

export default Process
