import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
let analytics: any = null
const logEvents = false

const firebaseConfig = {
  apiKey: 'AIzaSyC-XqPWo3qaj9gWyVUP-dMiDTPnCVQOQfk',
  authDomain: 'volt-349c6.firebaseapp.com',
  databaseURL: 'https://volt-349c6.firebaseio.com',
  projectId: 'volt-349c6',
  storageBucket: 'volt-349c6.appspot.com',
  messagingSenderId: '459299733708',
  appId: '1:459299733708:web:56db940d9116731b73c269',
  measurementId: 'G-0VTSZMFS9Z'
}

const startAnalytics = () => {
  firebase.initializeApp(firebaseConfig)
  analytics = firebase.analytics()
}

const logEvent = (event: any, params: any) => {
  if (!analytics) return console.log('Analytics not initialised yet')
  if (logEvents) {
    console.log(`%c${event}`, 'background: red; color: white', params)
  }
  analytics.logEvent(event, params)
}

const logPage = (screen: string) => {
  if (!analytics) return console.log('Analytics not initialised yet')
  if (logEvents) {
    console.log(`%c${screen}`, 'background: blue; color: white')
  }
  analytics.setCurrentScreen(screen)
}

export { startAnalytics, logEvent, logPage }
