import { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useGesture } from '@use-gesture/react'
import { useSpring, a } from '@react-spring/web'
import Image from '../Image'
import Svg from '../Svg'
import Video from '../Video'
import './slider.scss'

const Slider = ({ data }) => {
  const { slides, background, invert } = data
  const [index, setIndex] = useState(0)
  const [pressed, setPressed] = useState(false)
  const [{ x, scale }, api] = useSpring(() => ({
    config: { mass: 1, tension: 400, friction: 40 },
    to: { x: 0, scale: 1 }
  }))

  // Single slide removes nav and gestures
  const isSingle = slides.length < 2
  const className = `slider ${isSingle ? ' single' : ''}${
    pressed ? ' active' : ''
  }`

  // Actions
  const isPrev = index > 0
  const isNext = index < slides.length - 1
  const leftClass = `left${invert ? ' invert' : ''}${isPrev ? ' active' : ''}`
  const rightClass = `right${invert ? ' invert' : ''}${isNext ? ' active' : ''}`
  const goPrev = useCallback(() => {
    if (!isPrev) return
    setIndex(index - 1)
  }, [isPrev, index])
  const goNext = useCallback(() => {
    if (!isNext) return
    setIndex(index + 1)
  }, [isNext, index])
  const goIndex = useCallback(i => {
    setIndex(i)
  }, [])

  // Animate position on index change...
  const updateX = useCallback(() => {
    const newX = -window.innerWidth * index
    api.start({ x: newX })
  }, [index, api])
  useEffect(() => {
    updateX()
  }, [index, updateX])
  // ...And when window resizes
  useEffect(() => {
    window.addEventListener('resize', updateX)
    return () => window.removeEventListener('resize', updateX)
  }, [updateX])

  // Handle mouse gestures
  const active = useRef(false)
  const thresholdWheel = 20
  const handleMove = useCallback(
    (wheel, movement, delta, down) => {
      const [mX, mY] = movement
      const [dX] = delta
      const thresholds = [window.innerWidth * 0.15, window.innerHeight * 0.15]
      // Drag
      // Ensure mobile users don't get stuck by pushing up / down
      if (isMobile && Math.abs(mY) > thresholds[1]) {
        const y = window.scrollY
        const isDown = mY > 0
        const newY = isDown
          ? y - window.innerHeight * 0.66
          : y + window.innerHeight * 0.66
        window.scrollTo({
          top: newY,
          behavior: 'smooth'
        })
      }
      // Return here if only single slide
      if (isSingle) return
      if (Math.abs(mX) > thresholds[0]) {
        // If above the threshold then go to next slide
        if (!active.current) {
          const isLeft = dX < 0
          isLeft ? goNext() : goPrev()
          active.current = true
        }
      } else {
        // Otherwise slide towards direction of mouse/touch
        const newX = -window.innerWidth * index + mX
        api.start({ x: newX })
        active.current = false
      }
      // Mouse up / down
      if (!down) updateX()
      // Wheel
      if (wheel) {
        if (Math.abs(dX) > thresholdWheel) {
          if (!active.current) {
            const isLeft = dX > 0
            isLeft ? goNext() : goPrev()
            active.current = true
          }
        } else {
          active.current = false
        }
        return
      }
    },
    [isSingle, thresholdWheel, goNext, goPrev, index, api, updateX]
  )

  const handlePress = useCallback(
    down => {
      if (isSingle) return
      // Pressed state
      api.start({ scale: down ? 0.98 : 1 })
      setPressed(down)
      // Reset on release
      if (!down) updateX()
    },
    [isSingle, api, updateX]
  )

  const bind = useGesture({
    // Drag
    onDrag: ({ movement, delta, down }) =>
      handleMove(false, movement, delta, down),
    onDragStart: () => handlePress(true),
    onDragEnd: () => handlePress(false),
    // Wheel
    onWheel: ({ movement, delta, down }) =>
      handleMove(true, movement, delta, down)
  })

  // Keyboard
  useEffect(() => {
    if (isSingle) return
    const onKey = e => {
      const { key } = e
      const isCombination = e.altKey || e.ctrlKey || e.metaKey || e.shiftKey
      if (isCombination) return
      switch (key) {
        case 'Backspace':
          // Prevent default behaviours for keys
          e.preventDefault()
          goIndex(0)
          break
        case 'ArrowLeft':
          e.preventDefault()
          goPrev()
          break
        case 'ArrowRight':
          e.preventDefault()
          goNext()
          break
        default:
          // console.log(key)
          break
      }
    }
    document.addEventListener('keydown', onKey)
    return () => {
      document.removeEventListener('keydown', onKey)
    }
  }, [isSingle, goIndex, goPrev, goNext])

  return (
    <div className={className}>
      {/* Background */}
      {background && (
        <div className='background' style={{ background: background.style }}>
          {background.video ? (
            <Video fill id={background.video} />
          ) : (
            <Image
              fill
              id={background.image}
              params={{ width: 1920, height: 1080 }}
            />
          )}
        </div>
      )}

      <div {...bind()} className='inner'>
        {/* Slides */}
        <a.div className='slides' style={{ x }}>
          {slides.map((slide, slideIndex) => {
            const { image, video } = slide
            const isActive = slideIndex === index
            return (
              <a.div
                key={slideIndex}
                className='slide'
                style={{
                  transform: isActive ? scale.to(s => `scale(${s})`) : null
                }}
              >
                {video ? (
                  <Video id={video} />
                ) : (
                  <Image
                    id={image}
                    params={{ width: 1920, height: 1080, fit: 'inside' }}
                  />
                )}
              </a.div>
            )
          })}
        </a.div>

        {/* Arrows */}
        <div className={leftClass} onClick={goPrev}>
          <Svg name='chev-left' />
        </div>
        <div className={rightClass} onClick={goNext}>
          <Svg name='chev-right' />
        </div>
      </div>

      {/* Pagination */}
      {!isSingle && slides?.length > 0 && (
        <div className='pagination'>
          <div className='pagination-inner'>
            {slides.map((slide, slideIndex) => {
              const { title } = slide
              const isActive = slideIndex === index
              let cls = 'item'
              if (title) cls += ' title'
              if (invert) cls += ' invert'
              if (isActive) cls += ' active'
              return (
                <div
                  key={slideIndex}
                  className={cls}
                  onClick={() => !isActive && goIndex(slideIndex)}
                >
                  {title ? <p>{title}</p> : <div className='dot' />}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Slider
