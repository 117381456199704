import Image from '../../../components/Image'

const Grid = ({ data, activeColors, onClick }) => {
  const { items } = data
  return (
    <div className='grid'>
      {items?.length > 0 &&
        items.map((item, index) => {
          if (!item || !item.title || !item.image) return null
          const { id, image, title, body, backgroundColor, textColor } = item
          const isOnClick = id && onClick
          return (
            <div
              className='item'
              key={index}
              style={{ backgroundColor }}
              onClick={() => isOnClick && onClick(id)}
            >
              <Image id={image} params={{ width: 640, height: 640 }} />
              <div className='text'>
                <h3 style={{ color: textColor }}>{title}</h3>
                <p style={{ color: `${textColor}CC` }}>{body}</p>
              </div>
              {isOnClick && activeColors?.[index] && (
                <div
                  className='outline'
                  style={{ borderColor: activeColors[index] }}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}

export default Grid
