import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
// @ts-ignore
import tinycolor from 'tinycolor2'
import globals from '../globals/globals'

const useUrlParams = (keys: string[]) => {
  const { search } = useLocation()
  return useMemo(() => {
    const queryParams = new URLSearchParams(search)
    let res: any = {}
    keys.forEach(key => (res[key] = queryParams.get(key)))
    return res
  }, [keys, search])
}

const usePagination = (data: any, projectsData: any, page: string) => {
  const getP = useCallback(
    (prev: any, next: any) => ({
      prev: prev && {
        id: prev,
        title: projectsData[prev]?.title
      },
      next: next && {
        id: next,
        title: projectsData[next]?.title
      }
    }),
    [projectsData]
  )
  return useMemo(() => {
    let res: any = { prev: null, next: null }
    if (!data || !page) return res
    const { projects } = data
    if (!projects || projects.length < 2) return res
    const index = projects.indexOf(page)
    if (index < 1) {
      // First
      res = getP(null, projects[index + 1])
    } else if (index > projects.length - 2) {
      // Last
      res = getP(projects[index - 1], null)
    } else {
      // In between
      res = getP(projects[index - 1], projects[index + 1])
    }
    return res
  }, [data, page, getP])
}

const getDomain = () => {
  const isNz = window.location.href.includes('.nz')
  return isNz ? 'nz' : 'co.uk'
}

const getColors = (length: number, colors: string[]) => {
  const steps = length / (colors.length - 1)
  let index = -1
  let cols: string[] = []
  for (let i = 0; i < length; i++) {
    const pos = i % steps
    if (Math.floor(pos) === 0) index++
    const mix = (pos / steps) * 100
    const colA = colors[index]
    const colB = colors[index + 1]
    cols.push(tinycolor.mix(colA, colB, mix).toHexString())
  }
  return cols
}

const getYearsWorked = (): number => moment().diff('2009-01-01', 'years')

const animRiseStyles = (active: boolean, delay: number = 0) => {
  return {
    opacity: 0,
    animation: active ? `animRise 0.6s ${delay}ms forwards` : null
  }
}

const animIntroTextStyles = (colors: any[], delay: number) => {
  const defaultColor = globals.colors.white
  return colors.map((color, index) => {
    return {
      keyframes: `
      @keyframes animIntroColor${index} {
        0%   { color: ${defaultColor}; }
        25%  { color: ${color}; }
        100% { color: ${defaultColor}; }
      }
    `,
      styles: {
        color: defaultColor,
        animation: `animIntroColor${index} 2s ${delay + index * 150}ms forwards`
      }
    }
  })
}

export {
  useUrlParams,
  usePagination,
  getDomain,
  getColors,
  getYearsWorked,
  animRiseStyles,
  animIntroTextStyles
}
