import Svg from '../Svg'
import './button.scss'

const Button = ({ disabled, title, icon, style, onClick }) => {
  const className = icon ? `btn icon` : `btn`
  return (
    <button
      className={className}
      disabled={disabled}
      type='button'
      style={style}
      onClick={onClick}
    >
      {icon && <Svg name={icon} />}
      {title}
    </button>
  )
}

export default Button
