import { useRef } from 'react'
import { Row, Col } from '../../../components/Page'
import RichText from '../../../components/RichText'
import Svg from '../../../components/Svg'
import { animRiseStyles } from '../../../helpers/layoutHelper'
import { useScrolled } from '../../../helpers/interactionHelper'

const Summary = ({ data }) => {
  const divRef = useRef(null)
  const scrolled = useScrolled(divRef)

  const { title, body, cols, background } = data

  return (
    <div ref={divRef} className='summary'>
      {/* Background */}
      {background && (
        <div className='background' style={{ background: background.style }} />
      )}

      {/* Content */}
      <Row className='center max'>
        <Col>
          <h2>{title}</h2>
          <h4 className='long'>
            <RichText>{body}</RichText>
          </h4>
        </Col>
      </Row>
      {cols?.length > 0 && (
        <Row className='center max'>
          {cols.map((col, index) => {
            return (
              <Col key={index} style={animRiseStyles(scrolled, index * 100)}>
                {col.icon && (
                  <div
                    className='icon'
                    style={{
                      backgroundColor: `${col.iconColor}1A`,
                      borderColor: col.iconColor
                    }}
                  >
                    <Svg name={col.icon} style={{ fill: col.iconColor }} />
                  </div>
                )}
                <h3>{col.title}</h3>
                <p>
                  <RichText linkColor={col?.linkColor}>{col.body}</RichText>
                </p>
              </Col>
            )
          })}
        </Row>
      )}
    </div>
  )
}

export default Summary
