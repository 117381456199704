import * as THREE from 'three'

const HandShader = ({ colorA, colorB, opacity }) => {
  return {
    transparent: true,
    lights: true,
    uniforms: {
      ...THREE.UniformsLib['lights'],
      colorA: { value: colorA },
      colorB: { value: colorB },
      opacity: { value: opacity }
    },
    vertexShader: [
      'varying vec3 vPos;',
      'varying vec3 vNormal;',
      'uniform mat4 vMatrix;',
      'varying vec3 vEye;',
      'void main()',
      '{',
      'vPos = (modelMatrix * vec4(position, 1.0 )).xyz;',
      'vNormal = normalMatrix * normal;',
      'vec4 eyeSpaceVertexPos = modelViewMatrix * vec4(position, 1.0 );',
      'vEye = (vec3(eyeSpaceVertexPos)) / eyeSpaceVertexPos.w;',
      'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
      '}'
    ].join('\n'),
    fragmentShader: [
      'uniform vec3 diffuse;',
      'varying vec3 vPos;',
      'varying vec3 vNormal;',
      'varying vec3 vEye;',
      // Texure and colours
      'uniform vec3 colorA;',
      'uniform vec3 colorB;',
      // Lighting
      'struct PointLight {',
      'vec3 position;',
      'vec3 color;',
      '};',
      'uniform PointLight pointLights[ NUM_POINT_LIGHTS ];',
      'uniform mat4 vMatrix;',
      'uniform float opacity;',
      // Frag shader
      'void main() {',
      // Mix chosen colours
      'vec3 colMixed = mix(colorA, colorB, (vPos.x + 1.0) * 0.5);',
      //   'vec3 colMixed = mix(colorA, colorB, gl_FragCoord.x);',
      // Add lights
      'vec3 colLights = vec3(0.1, 0.1, 0.1);',
      'for(int l = 0; l < NUM_POINT_LIGHTS; l++) {',
      'vec3 adjustedLight = pointLights[l].position + cameraPosition;',
      'vec3 lightDirection = normalize(vPos - adjustedLight);',
      'colLights.rgb += clamp(dot(-lightDirection, vNormal), 0.0, 1.0) * pointLights[l].color;',
      '}',
      // Add rim / fresnel lighting for glow
      'float cosTheta = abs(dot(normalize(vEye), vNormal));',
      'float fresnel = pow(1.0 - cosTheta, 0.5);',
      'vec3 colTexLights = colMixed * colLights;',
      // Combine
      'vec3 colFresnel = mix(colTexLights, colorB, fresnel);',
      'gl_FragColor = vec4(colFresnel, opacity);',
      '}'
    ].join('\n')
  }
}
export default HandShader
