import { useRef } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'
import { useSpring, a } from '@react-spring/three'

const amount = 90
const posMin = window.innerWidth > 768 ? 0.007 : 0.005
const posMax = window.innerWidth > 768 ? 0.008 : 0.006
const sizeMin = 0.05
const sizeMax = 0.2
const speedMin = 2
const speedMax = 10
const nodes = Array.from({ length: amount }, () => {
  return {
    type: Math.random() > 0.5 ? 'box' : 'sphere',
    position: [Math.random() * (posMax - posMin) + posMin, 0, 0],
    size: [
      Math.random() * (sizeMax - sizeMin) + sizeMin,
      Math.random() * (sizeMax - sizeMin) + sizeMin,
      Math.random() * (sizeMax - sizeMin) + sizeMin
    ],
    startRotation: Math.random() * 360,
    speed: Math.random() * (speedMax - speedMin) + speedMin
  }
})

const Node = ({ loading, data }) => {
  const { type, size, startRotation, speed } = data

  // Rotate over time
  const groupRef = useRef(null)
  useFrame(({ clock }) => {
    if (!groupRef.current || loading) return
    const t = clock.getElapsedTime()
    const group = groupRef.current
    group.rotation.z = THREE.MathUtils.degToRad(startRotation + t * speed)
  })

  // Spring in
  const { position, scale, opacity } = useSpring({
    config: { mass: 1, tension: speed * 20, friction: 12 },
    delay: 1000,
    from: { position: [0, 0, 0], scale: [4, 4, 4], opacity: 0 },
    to: !loading && {
      position: [
        data.position[0] * window.innerHeight,
        data.position[1],
        data.position[2]
      ],
      scale: [1, 1, 1],
      opacity: (1 / speedMax) * speed
    }
  })

  return (
    <a.group ref={groupRef}>
      <a.mesh position={position} scale={scale}>
        {type === 'box' && <boxGeometry args={size} />}
        {type === 'sphere' && <sphereGeometry args={[size[0] * 0.5, 6, 6]} />}
        <a.meshLambertMaterial color='white' opacity={opacity} transparent />
      </a.mesh>
    </a.group>
  )
}

const Background = ({ loading, theme }) => {
  return (
    <div
      className='home-background'
      style={{ backgroundColor: theme.background }}
    >
      <Canvas
        camera={{
          fov: 30,
          position: [0, 0, 18],
          near: 0.1,
          far: 20000
        }}
      >
        {/* Lights */}
        <pointLight position={[0, 3, 0]} color={theme.primary} intensity={1} />
        <pointLight
          position={[3, 0, 1]}
          color={theme.secondary}
          intensity={1}
        />
        <pointLight
          position={[-3, 0, 1]}
          color={theme.tertiary}
          intensity={1}
        />

        {/* Nodes */}
        <group>
          {nodes.map((node, index) => (
            <Node loading={loading} key={index} data={node} />
          ))}
        </group>
      </Canvas>
    </div>
  )
}

export default Background
