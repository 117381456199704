import { createGraphiQLFetcher } from '@graphiql/toolkit'
import { GraphiQL } from 'graphiql'
import ReactDOM from 'react-dom'
import 'graphiql/graphiql.min.css'

const serverUrl = process.env.REACT_APP_DIRECTUS_URL

const GraphqlScreen = () => {
  const fetcher = createGraphiQLFetcher({
    url: `${serverUrl}/graphql`
  })

  ReactDOM.render(<GraphiQL fetcher={fetcher} />, document.body)
}

export default GraphqlScreen
