import { useRef } from 'react'
import { Group, Row, Col } from '../Page'
import Button from '../Button'
import Svg from '../Svg'
import { getDomain, usePagination } from '../../helpers/layoutHelper'
import { useScrolled, openEmail } from '../../helpers/interactionHelper'
import './footer.scss'

const Footer = ({ data, projectsData, page, onProject }) => {
  const divRef = useRef(null)
  const scrolled = useScrolled(divRef, 0.75)
  const domain = getDomain()
  const { prev, next } = usePagination(data, projectsData, page)
  const isFirst = !prev

  return (
    <footer>
      <div className={`pagination${isFirst ? ' first' : ''}`}>
        {prev && (
          <div className='left' onClick={() => onProject(prev.id)}>
            <Svg name='arrow-left' />
            <p>{prev.title}</p>
          </div>
        )}
        {next && (
          <div className='right' onClick={() => onProject(next.id)}>
            <p>{next.title}</p>
            <Svg name='arrow-right' />
          </div>
        )}
      </div>
      <Group>
        <Row ref={divRef} className='center'>
          <h2>Contact</h2>
        </Row>
        <Row className='contact center'>
          <Col>
            <h4>
              I'm always happy to chat shop, you can reach me on the details
              below.
            </h4>
            <div className={scrolled ? 'btn-wrapper active' : 'btn-wrapper'}>
              <div className='outline' />
              <Button
                title={`matt@volt.${domain}`}
                onClick={() => openEmail('matt')}
              />
            </div>
            <p>
              <a
                href='https://www.linkedin.com/in/matt-turnbull-design/'
                target='_blank'
                rel='noreferrer'
              >
                <Svg name='logo-linkedin' />
                matt-turnbull-design
              </a>
            </p>
            <p>
              <a href='tel:022 457 6775'>
                <Svg name='device-mobile' />
                +64 (0)22 457 6775
              </a>
            </p>
          </Col>
        </Row>
      </Group>
    </footer>
  )
}

export default Footer
