import '../styles/styles.scss'

const Layout = ({ className, children, style }) => (
  <main>
    <title>Volt</title>
    <div
      className={`container${className ? ` ${className}` : ''}`}
      style={style}
    >
      {children}
    </div>
  </main>
)

export default Layout
