import { Hero, Process, Summary, Grid } from './templates'
import { Card } from '../../components/Page'
import Slider from '../../components/Slider'

const FolioNapster = () => {
  return (
    <>
      {/* Hero */}
      <Hero
        data={{
          image: `napster-desktop-home.png`,
          video: `napster-desktop-home.vid`,
          icon: `logo-napster`,
          title: `Napster`,
          body: `Bringing **the original music streaming pioneers** back to life with immersive video and collectible swag.`,
          background: {
            style: `linear-gradient(rgba(102, 32, 255, 0.2), rgba(102, 32, 255, 0))`,
            image: null,
            video: null
          }
        }}
      />

      {/* Process */}
      <Process
        data={{
          title: `Timeline`,
          background: {
            style: `#2A0F66`
          },
          cols: [
            {
              title: `Launch 🚀`,
              subtitle: `1999`,
              type: `dashed`,
              body: `Sean Parker and team launch Napster – a P2P music sharing app that will disrupt the music industry forever. Over the next two decades the company evolves to legal streaming!`
            },
            {
              title: `MelodyVR & Video`,
              subtitle: `2020`,
              type: `highlight`,
              body: `[We acquire Napster](https://www.theguardian.com/music/2020/aug/25/napster-sold-british-startup-melodyvr-surprise-deal) to obtain its expertise, music catalogue and label rights. Work begins to **build a better home for music**, combining audio, video and live streams all under one roof.`,
              dotColor: `#2259FF`,
              linkColor: `#2259FF`
            },
            {
              title: `Algorand & Web3`,
              subtitle: `2022`,
              body: `[Algorand acquires Napster](https://www.forbes.com/sites/cathyolson/2023/02/15/napster-makes-its-first-web3-move) and hires an ex-Roblox CEO to demonstrate that their blockchain-based technology can enable new collectible and metaverse music experiences.`,
              dotColor: `#68DDFF`,
              linkColor: `#68DDFF`
            },
            {
              title: `Today`,
              subtitle: `2023`,
              body: `We leverage [further M&A's](https://decrypt.co/121341/napster-acquires-mint-songs-to-advance-its-web3-ambitions) and talent hires, with plans to take the company back to its decentralised roots, revolutionising the way Artists and Fans interact with one another.`,
              dotColor: `#68DDFF`,
              linkColor: `#68DDFF`
            }
          ]
        }}
      />

      {/* Mosiac */}
      <Card
        data={{
          background: {
            image: `napster-mosaic.jpg`
          }
        }}
      />

      {/* Summary */}
      <Summary
        data={{
          title: `Music from every angle`,
          body: `**We spoke with Artists and Fans** to understand their existing pains with music streaming, and used these learnings to form an evolving proposition aimed at fostering **true connections between music lovers**.`,
          cols: [
            {
              icon: `playlist-radio`,
              iconColor: `#2259FF`,
              title: `Music`,
              body: `Acquiring Napster (MelodyVR) bought us stronger ties to record labels, and enabled us to deliver an audio catalogue of **100m+ tracks, podcasts and editorial playlists** to Fans.`
            },
            {
              icon: `watch`,
              iconColor: `#68DDFF`,
              title: `Video`,
              body: `We migrated the best of our **[#68DDFF]4K and 360° video catalogue** onto the Napster platform, and scaled up with additional video ingestion from Sony, Warner and Universal Music Group.`
            },
            {
              icon: `artist`,
              iconColor: `#EAB8FF`,
              title: `Live Gigs`,
              body: `Bringing Melody's on-demand video streaming to the Napster platform enabled us to offer **[#EAB8FF]live streams from Artists and festivals** to music Fans around the world.`
            },
            {
              icon: `star`,
              iconColor: `#FFCA7D`,
              title: `Web3`,
              body: `We utilised the blockchain to enable **[#FFCA7D]Artists to offer collectible experiences** to their Fans. We explored ways to embrace the **[#FFCA7D]fandom and social aspects of music**.`
            }
          ]
        }}
      />

      {/* Slider */}
      <Slider
        data={{
          background: {
            style: `linear-gradient(rgba(34, 89, 255, 0.2), rgba(34, 89, 255, 0))`,
            image: null,
            video: null
          },
          invert: false,
          slides: [
            {
              title: `Audio & Video`,
              image: `napster-mobile-1.png`
            },
            {
              image: `napster-mobile-2.png`
            },
            {
              image: `napster-desktop-player-live.png`
            },
            {
              image: `napster-desktop-home.png`
            },
            {
              image: `napster-desktop-search.png`
            },
            {
              image: `napster-tv-home.png`
            },
            {
              image: `napster-collection-1.png`
            },
            {
              image: `napster-pitch.png`
            },
            {
              title: `Collectibles`,
              image: `napster-desktop-marketplace.png`
            },
            {
              title: `Social`,
              image: `napster-desktop-fanclubs.png`
            },
            {
              image: `napster-desktop-player-social.png`
            },
            {
              title: `Metaverse`,
              image: `napster-desktop-metaverse-1.png`
            },
            {
              image: `napster-desktop-metaverse-2.png`
            },
            {
              title: `Insights`,
              image: `napster-tablet-artistinsights.png`
            }
          ]
        }}
      />

      {/* Metaverse */}
      <Card
        data={{
          background: {
            image: `napster-metaverse.jpg`,
            video: `napster-metaverse.vid`
          }
        }}
      />

      {/* Grid */}
      <Grid
        data={{
          items: [
            {
              image: `napster-grid-jobs.png`,
              title: `Proposition`,
              body: `Talking with Fans and Artists helped us to identify and understand where their music streaming pain points lie. We used Jobs To Be Done theory to explore propositions that address these pains.`
            },
            {
              image: `napster-grid-plugin.png`,
              title: `Figma Plugin`,
              body: `We built our own team plugin to streamline our Design workflow, including: auto-filling of Artist content from our API, smart asset exporting for Engineering, team working hours, etc.`,
              backgroundColor: `#FFCA7D`,
              textColor: `#181D38`
            },
            {
              image: `napster-grid-prototype.png`,
              title: `Prototypes`,
              body: `Building several low and high fidelity prototypes enabled us to bring our design thinking to life, and to better inform discussions with stakeholders and engineers.`,
              backgroundColor: `#68DDFF`,
              textColor: `#181D38`
            },
            {
              image: `napster-grid-generative.png`,
              title: `Generative`,
              body: `To create more diverse editorial content, we experimented with ways of generating dynamic imagery based on colour picking of cover art and analysing the waveform and tempo of music.`
            },
            {
              image: `napster-grid-translations.png`,
              title: `Tools`,
              body: `We created a variety of tools for the wider business, enabling us to do things like: review our localised in-app text, learn more about Artist social behaviours beyond Napster, migrate user libraries onto our platform.`,
              backgroundColor: `#EAB8FF`,
              textColor: `#181D38`
            },
            {
              image: `napster-grid-web3.png`,
              title: `Web 3.0`,
              body: `We cut our teeth in the Web3 space, learning the technical foundations, and how to determine the latest hype from the true value it brings to users.`
            }
          ]
        }}
      />
    </>
  )
}

export default FolioNapster
