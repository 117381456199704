import './rich-text.scss'
const strongRegex = /\*\*(.*?)\*\*/g
const strongColorsRegex = /\[(.*?)\]/g
const linkContainerRegex = /\[(.*?)\)/g
const linkTitleRegex = /\[(.*?)\]/
const linkTitleDelimiterRegex = /\[|\]/g
const linkUrlRegex = /\((.*?)\)/
const linkUrlDelimiterRegex = /\(|\)/g

const parseLink = str => {
  const title = str
    .match(linkTitleRegex)?.[0]
    ?.replace(linkTitleDelimiterRegex, '')
  const url = str.match(linkUrlRegex)?.[0]?.replace(linkUrlDelimiterRegex, '')
  return { title, url }
}

const getStrong = html =>
  html?.replaceAll(strongRegex, (a, b) => {
    const isColor = strongColorsRegex.test(b)
    if (!isColor) return `<strong>${b}</strong>`
    const color = b.split(strongColorsRegex)?.[1]?.replace(/\[|\]/g, '')
    return `<strong style="color:${color}">${b.replace(
      strongColorsRegex,
      ''
    )}</strong>`
  })

const getLinks = (html, linkColor) =>
  html
    ?.split(linkContainerRegex)
    ?.map(chunk => {
      const isLink = chunk.includes('](')
      if (!isLink) return chunk
      const { title, url } = parseLink(`[${chunk})`)
      const style = linkColor
        ? ` style="text-decoration-color: ${linkColor}"`
        : ''
      return `<a href="${url}" target="_blank" rel="noreferrer"${style}>${title}</a>`
    })
    .join('')

const RichText = ({ children, linkColor }) => {
  const withLinks = getLinks(children, linkColor)
  const withStrong = getStrong(withLinks)
  return (
    <span
      className='rich-text'
      dangerouslySetInnerHTML={{ __html: withStrong }}
    />
  )
}

export default RichText
