import Intro from './FolioCvIntro'
import Experience from './FolioCvExperience'
import Projects from './FolioCvProjects'
import Skills from './FolioCvSkills'
import Process from './FolioCvProcess'
import globals from '../../../globals/globals'
import './folio-cv.scss'

const colors = [
  globals.colors.primary,
  globals.colors.secondary,
  globals.colors.tertiary
]

const Cv = ({ data, onProject }) => {
  const projects =
    data?.projects?.length > 0 && data.projects.filter(p => p !== 'cv')
  return (
    <>
      {/* Intro */}
      <Intro colors={colors} />

      {/* Experience */}
      <Experience colors={colors} />

      {/* Projects */}
      {projects && (
        <Projects projects={projects} colors={colors} onProject={onProject} />
      )}

      {/* Skills */}
      <Skills />

      {/* Process */}
      <Process colors={colors} />
    </>
  )
}

export default Cv
