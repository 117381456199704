import { Hero, Summary, Grid, Process } from './templates'
import Slider from '../../components/Slider'

const FolioVolt = () => {
  return (
    <>
      {/* Hero */}
      <Hero
        data={{
          image: `volt-hero-logo.png`,
          video: `volt-hero-logo.vid`,
          icon: `logo-volt`,
          title: `Volt`,
          body: `Helping people turn ideas into **exceptional digital products**.`,
          background: {
            style: null,
            image: null,
            video: null
          },
          overlay: null
        }}
      />

      {/* Process */}
      <Process
        data={{
          title: `Timeline`,
          cols: [
            {
              title: `Launch ⚡️`,
              subtitle: `2014`,
              type: `highlight`,
              body: `We (Matt and John) wound up our full time positions and founded Volt, beleiving that we could offer a **better process for building digital products** than other agencies.`
            },
            {
              title: `For Startups 🧪`,
              subtitle: `2015`,
              body: `We develop a **proposition and process tailored for startups**, helping them to rapidly create and validate their visions, through to delivering production apps into the hands of their end users.`
            },
            {
              title: `Growth spurt 🚀`,
              subtitle: `2016`,
              body: `We scaled up, bringing on contractors to meet demand, **and learning the ropes of managing teams**. We moved into a larger office and adapted our approach **towards bigger businesses**.`
            },
            {
              title: `Acquisition 🤝`,
              subtitle: `2018`,
              body: `Music streaming startup, **MelodyVR** reached out with the intention of **acquiring our business in-house**. After a transition period, we jumped aboard full time and put Volt on ice.`
            }
          ]
        }}
      />

      {/* Business */}
      <Summary
        data={{
          title: `Business-to-Business`,
          body: `We worked with a range of B2B companies, from series A startups, to SMEs and larger corporations.`,
          cols: [
            {
              icon: `topchart`,
              iconColor: `#FFFFFF`,
              title: `NextHype`,
              body: `We designed a platform for [Signal Noise](https://www.thedrum.com/news/2016/10/21/economist-group-acquires-signal-noise-disrupt-visual-data-storytelling-its-clients) and the Economist Group, aimed at helping innovators to track and forecast market trends.`
            },
            {
              icon: `video`,
              iconColor: `#FFFFFF`,
              title: `Genius Digital`,
              body: `[Genius Digital](https://geniusdigital.uk) contracted us to prototype a streaming analytics platform, where customers could gain insights on viewer behaviour.`
            },
            {
              icon: `grid`,
              iconColor: `#FFFFFF`,
              title: `Limejump`,
              body: `[Limejump](https://www.limejump.com) offer innovative ways to sell renewable energy across the UK National power grid, we helped them to articulate their proposition.`
            },
            {
              icon: `equalizer`,
              iconColor: `#FFFFFF`,
              title: `Reactive`,
              body: `[Reactive Technologies](https://reactive-technologies.com) were utilising telco tech to build the energy system of the future. We gave them a website and dashboard to match.`
            },
            {
              icon: `share-android`,
              iconColor: `#FFFFFF`,
              title: `Intellihep`,
              body: `Biochemistry spin-off, Intellihep use Heparin to seek cures for neurodegeneration and cancer. We helped them to reach their Scientific audience.`
            }
          ],
          background: {
            style: null
          }
        }}
      />

      {/* Slider */}
      <Slider
        data={{
          slides: [
            {
              title: `NextHype`,
              image: `volt-nexthype-1.png`
            },
            {
              image: `volt-nexthype-2.png`
            },
            {
              image: `volt-nexthype-3.png`
            },
            {
              title: `Genius Digital`,
              image: `volt-geniusdigital-1.png`
            },
            {
              image: `volt-geniusdigital-2.png`
            },
            {
              title: `Limejump`,
              image: `volt-limejump-1.png`
            },
            {
              title: `Reactive`,
              image: `volt-reactivetechnologies-1.png`
            },
            {
              image: `volt-reactivetechnologies-2.png`
            },
            {
              title: `Intellihep`,
              image: `volt-intellihep-1.png`
            },
            {
              image: `volt-intellihep-2.png`
            }
          ],
          background: {
            style: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1))`,
            image: null,
            video: null
          },
          invert: false
        }}
      />

      {/* Consumers */}
      <Summary
        data={{
          title: `Consumer Apps`,
          body: `We also had some fun building projects with consumer facing startups.`,
          cols: [
            {
              icon: `heart-active`,
              iconColor: `#FFFFFF`,
              title: `Antidate`,
              body: `We helped Antidate to design and ship a dating app with a twist – it put power back into the hands of women by enabling them to make the first move.`
            },
            {
              icon: `bookmark`,
              iconColor: `#FFFFFF`,
              title: `Vinterest`,
              body: `We worked with wine sommeliers and investors, [Vinterest](https://www.vinterest.uk) to create an app that delivers expert wine suggestions right to your next dinner party.`
            },
            {
              icon: `location`,
              iconColor: `#FFFFFF`,
              title: `GoEuro`,
              body: `GoEuro (now [Omio](https://www.omio.com)) enables travellers to book stress-free, multi-route tickets. We helped them deliver a better search and booking experience.`
            },
            {
              icon: `repeat`,
              iconColor: `#FFFFFF`,
              title: `SportPursuit`,
              body: `Athletic wholesalers, [SportPursuit](https://www.sportpursuit.com/) contracted us to improve the conversion rate on their online store with better search and suggestions UX.`
            }
          ],
          background: {
            style: null
          }
        }}
      />

      {/* Slider */}
      <Slider
        data={{
          slides: [
            {
              image: `volt-mobile-1.png`
            }
          ],
          background: {
            style: null,
            image: null,
            video: null
          },
          invert: false
        }}
      />

      {/* Grid */}
      <Grid
        data={{
          items: [
            {
              image: `volt-grid-monarch.png`,
              title: `Labwork`,
              body: `We played in our spare time 🦋, Encouraging a balance of internal projects and trinkering along with client work. Our learnings within 'The Lab' often informed our work with clients.`,
              backgroundColor: null,
              textColor: `#FFFFFF`
            },
            {
              image: `volt-grid-concepts.png`,
              title: `Workshops`,
              body: `Heavy metal day 🎸, reaching for sketch pads ✍️ and Post-it frenzies helped us to escape the mental confines of our keyboards. Sessions regularly involved clients and friends alike! `,
              backgroundColor: `#E3DFD9`,
              textColor: `#000000`
            },
            {
              image: `volt-grid-smartdevices.png`,
              title: `Going Physical`,
              body: `We explored building our own smart devices and IoT toys for productivity. We ventured into Industrial Design, and sought partners to help us manufacture physical prototypes. 🤖`,
              backgroundColor: `#7506F1`,
              textColor: `#FFFFFF`
            }
          ]
        }}
      />
    </>
  )
}

export default FolioVolt
