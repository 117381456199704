import { useCallback, useEffect, useRef, useState } from 'react'
import { getAssetUrl } from '../../helpers/dataHelper/dataHelper'
import { useScrolled } from '../../helpers/interactionHelper'
import { useUrlParams } from '../../helpers/layoutHelper'
import './video.scss'

const VideoComponent = ({
  id,
  params,
  active = true,
  fill,
  className,
  onLoad
}) => {
  const videoRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const { video } = useUrlParams(['video'])
  const scrolled = useScrolled(videoRef, 0.75)

  useEffect(() => {
    if (!videoRef.current || !scrolled) return
    videoRef.current.play()
  }, [scrolled])

  const handlePlay = useCallback(() => {
    if (!loading) return
    setLoading(false)
    onLoad && onLoad()
  }, [loading, onLoad])

  const isVideo = video !== 'false'
  if (!isVideo) return null

  let cls = 'video'
  if (fill) cls += ` fill`
  if (className) cls += ` ${className}`
  if (!loading && active) cls += ` active`

  return (
    <div className={cls}>
      <video
        // autoPlay
        ref={videoRef}
        loop
        muted
        playsInline
        preload='auto'
        poster={getAssetUrl(id.replace('.vid', '.png'), params)}
        onPlaying={handlePlay}
      >
        <source
          src={getAssetUrl(id.replace('.vid', '.mov'), params)}
          type='video/mp4;codecs=hvc1'
        />
        <source
          src={getAssetUrl(id.replace('.vid', '.webm'), params)}
          type='video/webm'
        />
      </video>
    </div>
  )
}

export default VideoComponent

// USE THE VIDEO CONVERTER IN /RESOURCES!
// Convert to WebM
// ffmpeg -i ~/Desktop/video.mov ~/Desktop/video.webm
// Convert a mov to HEVC with transparent/alpha channel
// avconvert -p PresetHEVC3840x2160WithAlpha -s ~/Desktop/video.mov -o ~/Desktop/output.mov --disableMetadataFilter --progress

// GCP Commands!!
// ------------------------
// ** Listing & Finding **
// gsutil ls gs://volt-site/vid
// gsutil ls -r gs://volt-site/vid
// gsutil ls gs://volt-site/vid | grep **FILENAME**
// ** Uploading **
// gsutil -m cp -r ~/Desktop/vid/* gs://volt-site/vid
