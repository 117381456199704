import { useEffect, useState } from 'react'
import { useGesture } from '@use-gesture/react'
import { Card, Group, Row, Col } from '../../../components/Page'
import Arrow from '../../../components/Arrow'
import {
  animIntroTextStyles,
  animRiseStyles,
  getColors,
  getYearsWorked
} from '../../../helpers/layoutHelper'

import Hand from './FolioCvIntroHand'

const Intro = ({ colors }) => {
  const [mouse, setMouse] = useState([0, 0])
  const [anim, setAnim] = useState(false)
  const phrases = [
    'I love crafting products',
    `over ${getYearsWorked()} years`,
    'teams of all shapes and sizes',
    'understand how to deliver digital'
  ]
  const bind = useGesture({
    onDrag: ({ xy }) => {
      setMouse([
        (xy[0] / window.innerWidth - 0.5) * 2,
        (xy[1] / window.innerHeight - 0.5) * 2
      ])
    },
    onMove: ({ xy }) => {
      setMouse([
        (xy[0] / window.innerWidth - 0.5) * 2,
        (xy[1] / window.innerHeight - 0.5) * 2
      ])
    }
  })

  // Keep animating text
  const animInDelay = 2300
  const animOutDelay = 4000
  useEffect(() => {
    let timeoutIn,
      timeoutOut = null
    timeoutIn = setTimeout(() => {
      !anim && setAnim(true)
      timeoutOut = setTimeout(() => {
        setAnim(false)
      }, animOutDelay)
    }, animInDelay)
    return () => {
      clearTimeout(timeoutIn)
      clearTimeout(timeoutOut)
    }
  }, [anim])

  const phrasesColors = getColors(phrases.length, colors)
  const phraseStyles = animIntroTextStyles(phrasesColors, 0)
  const span = i => (
    <span style={anim ? phraseStyles[i].styles : null}>{phrases[i]}</span>
  )
  return (
    <Group>
      {phraseStyles.map((p, i) => {
        return <style key={i}>{p.keyframes}</style>
      })}
      <Card>
        <div className='hand-events' {...bind()}>
          <Row className='intro center'>
            <Col>
              <Hand mouse={mouse} colors={colors} />
              <h1 style={animRiseStyles(true, 900)}>I'm Matt.</h1>
              <h1 className='long' style={animRiseStyles(true, 2000)}>
                {span(0)}. I've spent {span(1)} building with {span(2)},
                I've co-founded an agency and {span(3)} at all levels of
                business.
              </h1>
            </Col>
          </Row>
          <Arrow
            delay={3000}
            svgStyle={{ animation: `animColor 10s linear reverse infinite` }}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: window.innerHeight,
                behavior: 'smooth'
              })
            }
          />
        </div>
      </Card>
    </Group>
  )
}

export default Intro
