import { forwardRef } from 'react'
import Image from '../Image'
import Video from '../Video'

const Background = ({ data }) => {
  const { background } = data
  return (
    <div className='background' style={{ background: background.style }}>
      {background.video ? (
        <Video
          fill
          id={background.video}
          params={{ width: 1920, height: 1080 }}
        />
      ) : (
        <Image
          fill
          id={background.image}
          params={{ width: 1920, height: 1080 }}
        />
      )}
    </div>
  )
}

const Inner = ({ data }) => {
  const { image, video } = data
  return (
    <div className='inner'>
      {video ? (
        <Video
          id={video}
          params={{ width: 1920, height: 1080, fit: 'inside' }}
        />
      ) : (
        <Image
          id={image}
          params={{ width: 1920, height: 1080, fit: 'inside' }}
        />
      )}
    </div>
  )
}

const Card = forwardRef(({ className, children, data, ...props }, divRef) => {
  return (
    <div
      ref={divRef}
      className={`card${className ? ` ${className}` : ''}`}
      {...props}
    >
      {/* Background */}
      {data?.background && <Background data={data} />}

      {/* Image / Video */}
      {(data?.image || data?.video) && <Inner data={data} />}

      {/* Overlay */}
      {data?.overlay && (
        <div className='overlay' style={data?.overlay?.style} />
      )}

      {/* Content */}
      {children}
    </div>
  )
})

export default Card
