import { useRef } from 'react'
import { Group, Row, Col } from '../../../components/Page'
import Svg from '../../../components/Svg'
import { animRiseStyles, getColors } from '../../../helpers/layoutHelper'
import { useScrolled } from '../../../helpers/interactionHelper'

const experiences = [
  {
    id: 'nokia',
    title: `Nokia`,
    subtitle: `2010`,
    description: `Winning a D&AD design awared led me to joining the ranks at Nokia in their heyday, cutting my teeth in the world of mobile UX and UI design.`
  },
  {
    id: 'signalnoise',
    title: `Signal Noise`,
    subtitle: `2012`,
    description: `An information design agency, now owned by the Economist. I was lucky enough to work on a variety of data visualisation experiences.`
  },
  {
    id: 'canonical',
    title: `Canonical`,
    subtitle: `2013`,
    description: `Makers of the open source Linux platform, Ubuntu, used by Microsoft, Google and Sky. I joined their web team for a variety of B2B initiatives.`
  },
  {
    id: 'volt',
    title: `Volt`,
    subtitle: `2014`,
    description: `I co-founded Volt, a design agency aimed at helping startups (and later on larger businesses) to deliver exceptional digital products.`
  },
  {
    id: 'spotify',
    title: `Spotify`,
    subtitle: `2015`,
    description: `I helped the music streaming giants to rapidly explore richer listening experiences around exercise, partying and sharing of content.`
  },
  {
    id: 'melodyvr',
    title: `MelodyVR`,
    subtitle: `2017`,
    description: `Our agency went in house with a VR & AR music streaming startup, aimed at putting fans 'on stage' with their favourite artists and musicians.`
  },
  {
    id: 'napster',
    title: `Napster`,
    subtitle: `2021`,
    description: `MelodyVR acquires Napster to disrupt the market. I help them to overhaul and deliver a combined audio and video music streaming platform.`
  }
]

const Experience = ({ colors }) => {
  const divRef = useRef(null)
  const experienceColors = getColors(experiences.length, colors)
  const scrolled = useScrolled(divRef)
  return (
    <Group>
      <div ref={divRef} />
      <Row>
        <h2>Experience</h2>
      </Row>
      <Row className='experiences'>
        {experiences.map((item, index) => {
          const { id, title, subtitle, description } = item
          return (
            <Col key={index} style={animRiseStyles(scrolled, index * 100)}>
              <Svg name={`logo-${id}`} />
              <h4>{title}</h4>
              <p className='year'>{subtitle}</p>
              <hr style={{ borderBottomColor: experienceColors[index] }} />
              <p>
                <small>{description}</small>
              </p>
            </Col>
          )
        })}
      </Row>
    </Group>
  )
}

export default Experience
