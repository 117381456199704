const Button = ({ text, theme, style, onPress }) => {
  return (
    <div
      className='contact-button'
      onMouseDown={onPress}
      style={{
        color: theme.background,
        ...style
      }}
    >
      <div
        className='overlay'
        style={{
          background: `linear-gradient(45deg, ${theme.primary}, ${theme.secondary}`,
          backgroundSize: '400% 400%',
          animation: 'animContactButton 15s ease infinite'
        }}
      />
      <p>{text}</p>
    </div>
  )
}

export default Button
