import { useRef } from 'react'
import { Group, Row } from '../../../components/Page'
import { Grid } from '../templates'
import { animRiseStyles, getColors } from '../../../helpers/layoutHelper'
import { useScrolled } from '../../../helpers/interactionHelper'

const grid = {
  volt: {
    title: `Volt`,
    body: `Helping people turn ideas into exceptional digital products.`,
    textColor: `#FFFFFF`
  },
  spotify: {
    title: `Spotify`,
    body: `Delivering better contextual music experiences to fans.`,
    textColor: `#FFFFFF`
  },
  melodyvr: {
    title: `MelodyVR`,
    body: `Using VR and AR to put fans onstage with their favourite musicians.`,
    textColor: `#FFFFFF`
  },
  napster: {
    title: `Napster`,
    body: `Bringing back the cat, with immersive video and collectible swag.`,
    textColor: `#FFFFFF`
  }
}

const Projects = ({ projects, colors, onProject }) => {
  const divRef = useRef(null)
  const projectColors = getColors(projects.length, colors)
  const scrolled = useScrolled(divRef)
  const items = projects.map(id => ({
    id,
    image: `cv-grid-${id}.png`,
    ...grid[id]
  }))
  return (
    <Group>
      <div ref={divRef} />
      <Row className='projects' style={animRiseStyles(scrolled)}>
        <Grid
          data={{ items }}
          activeColors={projectColors}
          onClick={onProject}
        />
      </Row>
    </Group>
  )
}

export default Projects
