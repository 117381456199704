import svgsBase from './svgs'
import svgsVolt from './svgsVolt'
const svgs = { ...svgsBase, ...svgsVolt }

const SvgComponent = ({ name, size, colors, style, ...props }) => {
  const Comp = svgs[name] || svgs._missing

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      style={{
        width: size ? `${size}rem` : null,
        height: size ? `${size}rem` : null,
        fill: colors ? colors[0] : null,
        // stroke: colors ? colors[0] : null,
        ...style
      }}
      {...props}
    >
      <Comp />
    </svg>
  )
}

export default SvgComponent
