import Svg from '../Svg'
import './arrow.scss'

const Arrow = ({ delay = 0, color, svgStyle, onClick }) => {
  return (
    <div
      className={onClick ? 'arrow button' : 'arrow'}
      onClick={onClick}
      style={{ animation: `animArrow 2s ${delay}ms infinite` }}
    >
      <Svg name='arrow-down' colors={color ? [color] : null} style={svgStyle} />
    </div>
  )
}

export default Arrow
