import { useEffect, useState } from 'react'
import './spinner.scss'

const Spinner = ({ type = 'medium', active: activeProp, color }) => {
  const [active, setActive] = useState(true)
  const borderTopColor = color

  useEffect(() => {
    if (activeProp) return
    const timeout = setTimeout(() => setActive(false), 600)
    return () => clearTimeout(timeout)
  }, [activeProp])

  if (!active) return null
  return (
    <div className={`spinner ${type}${activeProp ? ' active' : ''}`}>
      <div className='inner'>
        <div style={{ borderTopColor }} />
        <div style={{ borderTopColor }} />
        <div style={{ borderTopColor }} />
        <div style={{ borderTopColor }} />
      </div>
    </div>
  )
}

export default Spinner
