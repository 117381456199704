import React from 'react'

const AnimText = ({ children, delay = 0 }) => {
  if (!children) return null
  const textArray = children.split('')

  return textArray.map((char, index) => (
    <span
      key={index}
      style={{
        opacity: 0,
        animationName: 'animTextIn',
        animationDuration: '150ms',
        animationFillMode: 'forwards',
        animationDelay: `${delay + index * 15}ms`
      }}
    >
      {char}
    </span>
  ))
}

export default AnimText
