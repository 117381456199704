import { forwardRef } from 'react'

const Row = forwardRef(({ className, children, ...props }, divRef) => {
  return (
    <div
      ref={divRef}
      className={`row${className ? ` ${className}` : ''}`}
      {...props}
    >
      {children}
    </div>
  )
})

export default Row
