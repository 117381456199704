import { Hero, Summary, Grid } from './templates'
import Slider from '../../components/Slider'
import { Card } from '../../components/Page'
import globals from '../../globals/globals'

const FolioMelodyVr = () => {
  return (
    <>
      {/* Hero */}
      <Hero
        data={{
          image: null,
          video: null,
          icon: `logo-melodyvr`,
          title: `MelodyVR`,
          body: `Using VR and AR to put **fans onstage with their favourite musicians**.`,
          background: {
            style: null,
            image: `melodyvr-360.jpg`,
            video: `melodyvr-360.vid`
          },
          overlay: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))`
        }}
      />

      {/* Summary */}
      <Summary
        data={{
          title: `Stand on stage with your favourite acts`,
          body: `We took music fans to **new worlds**, delivering an end-to-end solution for **capturing and streaming 360° music at scale**.`,
          cols: [
            {
              icon: `watch`,
              iconColor: `#F52459`,
              title: `360° Video`,
              body: `We captured and delivered hundreds of on-demand shows to music fans, including **Imagine Dragons**, **Kelly Clarkson**, **Gorillaz**, **John Legend**, and many more blockbuster acts.`
            },
            {
              icon: `artist`,
              iconColor: `#F52459`,
              title: `Live Shows`,
              body: `Advances in our R&D, camera tech and consumer product enabled us to offer live streams of global gigs at scale, staggered over multiple timezones and in real time / after events occured.`
            },
            {
              icon: `people-3`,
              iconColor: `#F52459`,
              title: `Festivals`,
              body: `With the likes of [Ticketmaster](https://www.nme.com/news/music/watch-stefflon-don-and-more-play-virtual-wireless-festival-2701458), [Live Nation](https://liveforlivemusic.com/news/live-nation-uk-melodyvr-o2-academy-brixton-virtual-reality-series/) and [Good Morning America](https://www.goodmorningamerica.com/culture/story/good-morning-america-2019-summer-concert-series-lineup-62660647) we delivered whole festivals to Fans – Save money, beat the crowrds, and always get the best seats in the house.`,
              linkColor: `#F52459`
            },
            {
              icon: `heart`,
              iconColor: `#F52459`,
              title: `Interactive`,
              body: `We explored bringing Fans several mixed reality interactive experience projects with music industry legends, including **John Williams**, **Pink Floyd** and **The Beatles**.`
            }
          ]
        }}
      />

      {/* Artists */}
      <Card
        data={{
          background: {
            image: `melodyvr-artists.png`
          },
          overlay: {
            style: {
              boxShadow: `inset 0 0 2rem ${globals.colors.black}`,
              borderTop: '2px solid #F52459',
              borderBottom: '2px solid #F52459'
            }
          }
        }}
      />

      {/* Slider */}
      <Slider
        data={{
          background: {
            style: null,
            image: `melodyvr-vr-background.jpg`,
            video: `melodyvr-vr-background.vid`
          },
          invert: true,
          slides: [
            {
              title: `VR`,
              image: `melodyvr-vr-home.png`
            },
            {
              image: `melodyvr-vr-player.png`
            },
            {
              title: `Mobile`,
              image: `melodyvr-mobile-1.png`
            },
            {
              image: `melodyvr-mobile-2.png`
            },
            {
              image: `melodyvr-mobile-viewer.png`
            },
            {
              image: `melodyvr-mobile-3.png`
            },
            {
              image: `melodyvr-mobile-4.png`
            },
            {
              image: `melodyvr-originals-1.png`
            },
            {
              title: `Originals`,
              image: `melodyvr-originals-2.png`
            },
            {
              image: `melodyvr-originals-3.png`
            },
            {
              image: `melodyvr-originals-4.png`
            },
            {
              image: `melodyvr-originals-5.png`
            },
            {
              image: `melodyvr-originals-6.png`
            },
            {
              title: `Sessions`,
              image: `melodyvr-sessions-1.png`
            },
            {
              image: `melodyvr-sessions-2.png`
            }
          ]
        }}
      />

      {/* Grid */}
      <Grid
        data={{
          items: [
            {
              image: `melodyvr-grid-360.png`,
              title: `360° Live`,
              body: `We built a cross platform catalogue and player app using a combination of Unity (VR & AR), React Native (Mobile) and React (Web / Desktop).`,
              backgroundColor: `#F0BDFF`,
              textColor: `#000000`
            },
            {
              image: `melodyvr-grid-partnerships.jpg`,
              title: `Partnerships`,
              body: `Our industry connections and expertise enabled us to explore ways of revitalising epic back catalogues in new and engaging ways.`,
              backgroundColor: `#79D1FF`,
              textColor: `#000000`
            },
            {
              image: `melodyvr-grid-storytelling.jpg`,
              title: `Storytelling`,
              body: `We worked with the likes of John Williams to bring his music to a new audience, turning his scores into fun and interactive mini-stories.`,
              backgroundColor: `#ACFF8F`,
              textColor: `#000000`
            },
            {
              image: `melodyvr-grid-interactive.jpg`,
              title: `Interactive`,
              body: `We created interfaces to compliment 3D virtual environments, and utilise the latest immersive controller technology in engaging ways.`,
              backgroundColor: `#C808AF`,
              textColor: `#FFFFFF`
            },
            {
              image: `melodyvr-grid-prototypes.jpg`,
              title: `Prototypes`,
              body: `Dedicated R&D time, and rapid prototyping enabled the Design team to realise and share thinking in more coherent ways.`,
              backgroundColor: `#FF9431`,
              textColor: `#000000`
            },
            {
              image: `melodyvr-grid-unity.png`,
              title: `Game Engine`,
              body: `Building our core app in Unity gave us a deeper relationship with Engineering teams, and the opportunity to paint rich, interactive worlds.`,
              backgroundColor: `#B71650`,
              textColor: `#FFFFFF`
            }
          ]
        }}
      />
    </>
  )
}

export default FolioMelodyVr
