import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import tinycolor from 'tinycolor2'
import { Group, Row, Col } from '../../../components/Page'
import globals from '../../../globals/globals'
import Image from '../../../components/Image'
import { useScrolled } from '../../../helpers/interactionHelper'

const data = {
  name: 'Skills',
  children: [
    {
      icon: 'code',
      name: 'Development',
      children: [
        { name: 'Prototyping', size: 16 },
        { name: 'Web', size: 6 },
        { name: 'Native', size: 6 },
        { name: 'React', size: 6 },
        { name: 'Electron', size: 6 },
        { name: 'Flutter', size: 6 },
        { name: 'JS & TS', size: 3 },
        { name: 'HTML & CSS', size: 3 },
        { name: 'Python', size: 3 },
        { name: 'Unity', size: 3 }
      ]
    },
    {
      icon: 'people',
      name: 'People',
      children: [
        { name: 'Leadership', size: 16 },
        { name: 'Tenacity', size: 8 },
        { name: 'Empathy', size: 8 },
        { name: 'Autonomy', size: 8 },
        { name: 'Responsibility', size: 8 },
        { name: 'Presentation', size: 6 },
        { name: 'Hiring', size: 4 }
      ]
    },
    {
      icon: 'star',
      name: 'Design',
      children: [
        { name: 'Cinema 4D', size: 4 },
        { name: 'After Effects', size: 4 },
        { name: 'Photoshop', size: 4 },
        { name: 'Illustrator', size: 4 },
        { name: 'Figma', size: 6 },
        { name: 'Animation', size: 8 },
        { name: '3D', size: 10 },
        { name: 'Brand', size: 12 },
        { name: 'Product', size: 24 }
      ]
    }
  ]
}
const dataChildrenMaxSize = 16

const icons = {
  star: `<path fill-rule="evenodd" clip-rule="evenodd" d="M14.844 7.94202L12 0L9.15405 7.94202H1L7.31006 13.424L5.12988 20.9719L12 16.7959L18.8699 20.9719L16.689 13.424L23 7.94202H14.844ZM17.6477 9.94202H13.4358L11.9994 5.93061L10.5619 9.94202H6.35169L9.58596 12.7518L8.47499 16.5981L12 14.4554L15.5242 16.5976L14.4129 12.7518L17.6477 9.94202Z" />`,
  code: `<path fill-rule="evenodd" clip-rule="evenodd" d="M8.08622 18.0938L14.0862 4.59375L15.9138 5.40603L9.91384 18.906L8.08622 18.0938ZM4.42596 11.9999L7.71885 8.707L6.30464 7.29278L1.59753 11.9999L6.30464 16.707L7.71885 15.2928L4.42596 11.9999ZM19.5975 11.9999L16.3046 8.707L17.7189 7.29278L22.426 11.9999L17.7189 16.707L16.3046 15.2928L19.5975 11.9999Z" />`,
  people: `
    <path d="M6.08574 7.3933C5.29828 7.1246 4.73195 6.37844 4.73195 5.5C4.73195 4.39543 5.62738 3.5 6.73195 3.5C7.44081 3.5 8.06353 3.86877 8.41873 4.42493C8.98475 4.05036 9.60736 3.77642 10.259 3.61162C9.58469 2.35473 8.25814 1.5 6.73195 1.5C4.52281 1.5 2.73195 3.29086 2.73195 5.5C2.73195 7.36322 4.00588 8.92891 5.73024 9.37354C5.73642 8.70117 5.8556 8.03153 6.08574 7.3933Z" />
    <path d="M19.2678 5.5C19.2678 6.37844 18.7015 7.1246 17.914 7.3933C18.1441 8.03153 18.2633 8.70117 18.2695 9.37354C19.9939 8.92891 21.2678 7.36322 21.2678 5.5C21.2678 3.29086 19.4769 1.5 17.2678 1.5C15.7416 1.5 14.4151 2.35473 13.7407 3.61162C14.3924 3.77642 15.015 4.05036 15.581 4.42493C15.9362 3.86877 16.5589 3.5 17.2678 3.5C18.3724 3.5 19.2678 4.39543 19.2678 5.5Z" />
    <path d="M18.0332 11.0967C17.8337 11.7861 17.5127 12.432 17.0875 13.0048C19.408 13.1053 21.3183 14.7874 21.7675 17H23.7966C23.366 13.9898 21.0203 11.5974 18.0332 11.0967Z" />
    <path d="M5.96655 11.0967C6.16608 11.7861 6.48706 12.432 6.91223 13.0048C4.59175 13.1053 2.68142 14.7874 2.23226 17H0.203125C0.633719 13.9898 2.9795 11.5974 5.96655 11.0967Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 13.5C14.209 13.5 15.9999 11.7091 15.9999 9.5C15.9999 7.29086 14.209 5.5 11.9999 5.5C9.79074 5.5 7.99988 7.29086 7.99988 9.5C7.99988 11.7091 9.79074 13.5 11.9999 13.5ZM11.9999 11.5C13.1044 11.5 13.9999 10.6046 13.9999 9.5C13.9999 8.39543 13.1044 7.5 11.9999 7.5C10.8953 7.5 9.99988 8.39543 9.99988 9.5C9.99988 10.6046 10.8953 11.5 11.9999 11.5Z" />
    <path d="M11.9998 17C14.498 17 16.6 18.6963 17.2167 21H19.2677C18.6132 17.5825 15.6081 15 11.9998 15C8.39153 15 5.38641 17.5825 4.73193 21H6.78295C7.39969 18.6963 9.50166 17 11.9998 17Z" />
  `
}
const colors = [
  globals.colors.primary,
  globals.colors.secondary,
  globals.colors.tertiary
]

const Chart = ({ active }) => {
  const divRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [profile, setProfile] = useState(false)

  // Resize to div, handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (!divRef.current) return
      const { width: divWidth } = divRef.current.getBoundingClientRect()
      setWidth(divWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [divRef])

  // Render to target SVG
  useEffect(() => {
    if (!width) return
    const height = width
    const radius = width / 2
    const icon = i => {
      return icons[i]
    }
    const color = d3.scaleOrdinal().range(colors)
    const darken = d3
      .scaleLinear()
      .domain([0, dataChildrenMaxSize])
      .range([40, 10])

    // Clear last frame
    d3.select('#skills').selectAll('*').remove()
    d3.select('#tooltip').remove()

    // Parition data, find root
    const partition = d3.partition().size([2 * Math.PI, radius])
    const root = d3.hierarchy(data).sum(d => {
      return d.size
    })
    partition(root)

    // Build new chart
    const svg = d3
      .select('#skills')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + radius + ',' + radius + ')')

    // Stop rendering here if not active
    if (!active) return

    // Size arcs
    const arc = d3
      .arc()
      .startAngle(d => {
        return d.x0
      })
      .endAngle(d => {
        return d.x1
      })
      .innerRadius(d => {
        return d.y0
      })
      .outerRadius(d => {
        return d.y1
      })

    // Define the div for the tooltip
    const tooltip = d3.select('body').append('div').attr('id', 'tooltip')

    // Render frame
    const g = svg
      .selectAll('path')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .on('mouseover', (e, d) => {
        // console.log(d)
        tooltip
          .html(`<p>${d.data.name}</p>`)
          .style('left', `${e.pageX}px`)
          .style('top', `${e.pageY}px`)
          .style('opacity', 1)
      })
      .on('mouseout', () => {
        tooltip.style('opacity', 0)
      })
      // Animation
      .style('opacity', 0)
      .style('animation', (d, i) => {
        return `animSkills ${d.children ? 600 : 300}ms ${
          d.children ? 0 : i * 33
        }ms forwards`
      })
    // Path
    g.append('path')
      .attr('display', d => {
        return d.depth ? null : 'none'
      })
      .attr('aria-label', d => {
        return d.data.name
      })
      .attr('d', arc)
      .style('stroke', '#000')
      .style('stroke-width', '2px')
      .style('fill', d => {
        const rootCol = color((d.children ? d : d.parent).data.name)
        if (d.children) return rootCol
        const col = tinycolor(rootCol).darken(darken(d.value)).toString()
        return col
      })
    // Icon
    const iconSize = radius * 0.12
    g.append('g')
      .filter(d => {
        return d.depth === 1
      })
      .attr('class', 'icon')
      .html(d => {
        return icon(d.data.icon)
      })
      .attr('transform', d => {
        return `translate(
          ${arc.centroid(d)[0] - iconSize / 2},
          ${arc.centroid(d)[1] - iconSize / 2})
          scale(${iconSize / 24})`
      })

    // Text
    const angle = d => {
      const thetaDeg =
        ((180 / Math.PI) * (arc.startAngle()(d) + arc.endAngle()(d))) / 2 - 90
      return thetaDeg > 90 ? thetaDeg - 180 : thetaDeg
    }
    g.append('text')
      .filter(d => {
        return d.depth === 2
      })
      .text(d => {
        return d.data.name
      })
      .classed('label', true)
      .attr('text-anchor', 'middle')
      .attr('transform', d => {
        if (d.depth > 0) {
          return `translate(${arc.centroid(d)}), rotate(${angle(d)})`
        } else {
          return null
        }
      })
      .attr('dy', '.25rem')
      .attr('font-size', `${radius * 0.042}px`)
  }, [width, active])

  return (
    <div ref={divRef} className='skills'>
      <div
        className='profile'
        onMouseEnter={() => setProfile(true)}
        onMouseLeave={() => setProfile(false)}
      >
        <Image
          fill
          id='cv-profile.jpg'
          params={{ width: 320, height: 320 }}
          className='img-profile'
        />
        <Image
          fill
          id='cv-profile-hover.jpg'
          params={{ width: 320, height: 320 }}
          className='img-profile'
          active={profile}
        />
      </div>
      <svg id='skills' />
    </div>
  )
}

const Skills = () => {
  const divRef = useRef(null)
  const scrolled = useScrolled(divRef)
  return (
    <Group>
      <div ref={divRef} />
      <Row>
        <h2>Skills &amp; Tools</h2>
      </Row>
      <Row>
        <Col className='center'>
          <Chart active={scrolled} />
        </Col>
      </Row>
      <Row className='center'>
        <Col>
          <h4 className='long'>
            I’m a{' '}
            <span
              className='underline'
              style={{ textDecorationColor: globals.colors.primary }}
            >
              <span
                className='square'
                style={{ backgroundColor: globals.colors.primary }}
              />
              product designer
            </span>{' '}
            at heart, UX and UI are my jam. I enhance what I do with my{' '}
            <span
              className='underline'
              style={{ textDecorationColor: globals.colors.secondary }}
            >
              <span
                className='square'
                style={{ backgroundColor: globals.colors.secondary }}
              />
              development skills
            </span>
            ; building proof-of-concepts, optimising workflows, and
            supercharging handover with engineering teams. I'm a{' '}
            <span
              className='underline'
              style={{ textDecorationColor: globals.colors.tertiary }}
            >
              <span
                className='square'
                style={{ backgroundColor: globals.colors.tertiary }}
              />
              people person
            </span>
            , I can tackle projects solo, but prefer leading and collaborating
            with others to deliver rockstar experiences.
          </h4>
        </Col>
      </Row>
    </Group>
  )
}

export default Skills

// Old potentially useful bits for the future, future Matt.
// .append('circle')
// .attr('cx', iconSize / 2)
// .attr('cy', iconSize / 2)
// .attr('r', iconSize / 2)
// .style('stroke', 'red')
// Text
// g.append('text')
//   .filter(function (d) {
//     return d.depth === 1
//   })
//   .text(d => {
//     return d.data.name
//   })
//   .classed('label', true)
//   .attr('text-anchor', 'middle')
//   .attr('transform', d => {
//     if (d.depth > 0) {
//       return 'translate(' + arc.centroid(d) + ')' + 'rotate(0)'
//     } else {
//       return null
//     }
//   })
//   .attr('dy', '.25rem')
//   .attr('pointer-events', 'none')

// Text (alternative)
// const angle = d => {
//   const thetaDeg =
//     ((180 / Math.PI) * (arc.startAngle()(d) + arc.endAngle()(d))) / 2 - 90
//   return thetaDeg > 90 ? thetaDeg - 180 : thetaDeg
// }
// g.append('text')
//   .text(d => {
//     return d.data.name
//   })
//   .classed('label', true)
//   .attr('text-anchor', 'middle')
//   .attr('transform', d => {
//     if (d.depth > 0) {
//       return (
//         'translate(' + arc.centroid(d) + ')' + 'rotate(' + angle(d) + ')'
//       )
//     } else {
//       return null
//     }
//   })
//   .attr('dy', '.25rem')
//   .attr('pointer-events', 'none')
