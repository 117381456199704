const white = '255, 255, 255'
const black = '0, 0, 0'

const globals = {
  colors: {
    // Colours
    primary: '#FF005C',
    secondary: '#A000FF',
    tertiary: '#0038FF',
    // Monos
    white: `rgb(${white})`,
    white100: `rgb(${white})`,
    white90: `rgba(${white}, 0.9)`,
    white80: `rgba(${white}, 0.8)`,
    white70: `rgba(${white}, 0.7)`,
    white60: `rgba(${white}, 0.6)`,
    white50: `rgba(${white}, 0.5)`,
    white40: `rgba(${white}, 0.4)`,
    white30: `rgba(${white}, 0.3)`,
    white20: `rgba(${white}, 0.2)`,
    white10: `rgba(${white}, 0.1)`,
    white5: `rgba(${white}, 0.05)`,
    white0: `rgba(${white}, 0)`,
    black: `rgb(${black})`,
    black100: `rgb(${black})`,
    black90: `rgba(${black}, 0.9)`,
    black80: `rgba(${black}, 0.8)`,
    black70: `rgba(${black}, 0.7)`,
    black60: `rgba(${black}, 0.6)`,
    black50: `rgba(${black}, 0.5)`,
    black40: `rgba(${black}, 0.4)`,
    black30: `rgba(${black}, 0.3)`,
    black20: `rgba(${black}, 0.2)`,
    black10: `rgba(${black}, 0.1)`,
    black5: `rgba(${black}, 0.05)`,
    black0: `rgba(${black}, 0)`
  }
}

export default globals
