import { useRef, useState, useMemo, useEffect } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Canvas, useFrame } from '@react-three/fiber'
import { useSpring, a } from '@react-spring/three'
import HandShader from '../../../shaders/HandShader'

const rotAngle = 30
const waveAngle = 15
const pivot = [-0.5, 0.5, 0]
const duration = 600
const delay = 600

const HandScene = ({ colors, mouse, anim }) => {
  const loading = useRef(true)
  const groupRef = useRef(null)
  const meshRef = useRef(null)
  const primRef = useRef(null)

  // Load models
  const [obj, setObj] = useState(null)
  useMemo(() => {
    if (!loading.current) return
    new OBJLoader().load(`${process.env.PUBLIC_URL}/obj/hand.obj`, model => {
      // Update base material of each mesh
      const mat = new THREE.ShaderMaterial(
        HandShader({
          colorA: new THREE.Color(colors[0]),
          colorB: new THREE.Color(colors[2]),
          opacity: 0
        })
      )
      //   const mat = new THREE.MeshStandardMaterial({
      //     color: 'white',
      //     roughness: 0,
      //     envMapIntensity: 0.2,
      //     emissive: '#370037'
      //   })
      //   const mat = new THREE.MeshLambertMaterial({
      //     color: 'white',
      //     opacity: 1,
      //     transparent: true
      //   })
      model.traverse(m => {
        if (!m.material) return
        m.material = mat
      })
      loading.current = false
      // Set model in state
      setObj(model)
    })
  }, [colors])

  // Wave animation
  const { rot } = useSpring({
    from: { rot: 0 },
    to: { rot: anim ? 1 : 0 }
  })
  useFrame(({ clock }) => {
    if (!meshRef.current) return
    const t = clock.getElapsedTime()
    const mesh = meshRef.current
    mesh.rotation.z = THREE.MathUtils.degToRad(
      Math.sin(t * 24) * waveAngle * rot.get()
    )

    const group = groupRef.current
    group.position.y = Math.sin(t * 2) * 0.1

    // Fade in after load
    if (!primRef.current) return
    const prim = primRef.current
    prim?.traverse(m => {
      const val = m?.material?.uniforms?.opacity?.value
      if (val !== undefined && val < 1) {
        m.material.uniforms.opacity.value += 0.03
      }
    })
  })

  // Spring movement
  const { rotation, lightIntensity } = useSpring({
    from: { rotation: [0, 0, 0], lightIntensity: 0 },
    to: {
      rotation: [
        THREE.MathUtils.degToRad(mouse[1] * rotAngle),
        THREE.MathUtils.degToRad(mouse[0] * rotAngle),
        0
      ],
      lightIntensity: Math.max(0.5, 1 - Math.abs(mouse[0]))
    }
  })

  return (
    <>
      {/* Lights */}
      <a.pointLight
        position={[0, 2, 2]}
        color='white'
        intensity={lightIntensity}
      />

      {/* Action */}
      <a.group ref={groupRef} rotation={rotation}>
        <a.group ref={meshRef} position={[-pivot[0], -pivot[1], -pivot[2]]}>
          <a.mesh position={pivot}>
            {obj && <primitive ref={primRef} object={obj} />}
          </a.mesh>
        </a.group>
      </a.group>
    </>
  )
}

const Hand = ({ colors, mouse }) => {
  const [anim, setAnim] = useState(false)

  // Reset animation after duration
  useEffect(() => {
    if (!anim) return
    const timeout = setTimeout(() => setAnim(false), duration)
    return () => clearTimeout(timeout)
  }, [anim])

  // Animate on mount
  useEffect(() => {
    const timeout = setTimeout(() => setAnim(true), delay)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div
      className='hand'
      onMouseEnter={() => setAnim(true)}
      onClick={() => setAnim(true)}
    >
      <Canvas
        camera={{
          fov: 30,
          position: [0, 0, 5],
          near: 0.1,
          far: 20000
        }}
      >
        <HandScene colors={colors} mouse={mouse} anim={anim} />
      </Canvas>
    </div>
  )
}

export default Hand
