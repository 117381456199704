import { useRef } from 'react'
import { Row, Col } from '../../../components/Page'
import RichText from '../../../components/RichText'
import { animRiseStyles } from '../../../helpers/layoutHelper'
import { useScrolled } from '../../../helpers/interactionHelper'

const Process = ({ data }) => {
  const divRef = useRef(null)
  const scrolled = useScrolled(divRef)

  const { title, body, cols, background } = data

  return (
    <div ref={divRef} className='process'>
      {/* Background */}
      {background && (
        <div className='background' style={{ background: background.style }} />
      )}

      {/* Content */}
      <Row className='center max'>
        <Col>
          <h2>{title}</h2>
          {body && (
            <h4 className='long'>
              <RichText>{body}</RichText>
            </h4>
          )}
        </Col>
      </Row>
      {cols?.length > 0 && (
        <Row className='max'>
          {cols.map((col, index) => {
            const {
              title: colTitle,
              subtitle: colSubtitle,
              body: colBody,
              type,
              dotColor,
              linkColor
            } = col
            const isLast = index > cols.length - 2
            const isDashed = type === 'dashed'
            const isHighlight = type === 'highlight'
            const dotStyles = dotColor && {
              backgroundColor: isDashed ? null : dotColor,
              borderColor: isDashed ? dotColor : null
            }
            const lineStyles = dotColor && {
              borderColor: dotColor
            }
            return (
              <Col
                className='step'
                key={index}
                style={animRiseStyles(scrolled, index * 100)}
              >
                <div className={`dot${type ? ` ${type}` : ''}`}>
                  {isHighlight && (
                    <div className='node highlight' style={dotStyles} />
                  )}
                  <div
                    className='node'
                    style={!isHighlight ? dotStyles : null}
                  />
                </div>
                {!isLast && (
                  <div
                    className={`line${type ? ` ${type}` : ''}`}
                    style={lineStyles}
                  />
                )}
                <h4>{colTitle}</h4>
                <p className='year'>{colSubtitle}</p>
                <p>
                  <RichText linkColor={linkColor}>{colBody}</RichText>
                </p>
              </Col>
            )
          })}
        </Row>
      )}
    </div>
  )
}

export default Process
