import { useEffect, useState, useRef, useCallback } from 'react'
import { getAssetUrl } from '../../helpers/dataHelper/dataHelper'
import './image.scss'

const ImageComponent = ({
  id,
  params,
  active = true,
  fill,
  className,
  onLoad
}) => {
  const imgRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [src, setSrc] = useState(null)
  const url = getAssetUrl(id, params)

  const handleLoad = useCallback(() => {
    if (onLoad) onLoad()
    setLoading(false)
  }, [onLoad])

  useEffect(() => {
    if (!imgRef.current) return
    const img = new Image()
    img.src = url
    img.onload = () => {
      setSrc(url)
      handleLoad()
    }
  }, [url, handleLoad])

  let cls = 'image'
  if (fill) cls += ` fill`
  if (className) cls += ` ${className}`
  if (!loading && active) cls += ` active`

  return (
    <div ref={imgRef} className={cls}>
      <img ref={imgRef} src={src} alt={id} />
    </div>
  )
}

export default ImageComponent

// GCP Commands!!
// ------------------------
// ** Listing & Finding **
// gsutil ls gs://volt-site/img
// gsutil ls -r gs://volt-site/img
// gsutil ls gs://volt-site/img | grep **FILENAME**
// ** Uploading **
// gsutil -m cp -r ~/Desktop/img/* gs://volt-site/img
