import { useEffect, useState, useCallback, useRef } from 'react'
import { useGesture } from '@use-gesture/react'
import Content from './HomeContent'
import Background from './HomeBackground'
import Logo from './HomeLogo'
import { logEvent } from '../../helpers/analyticsHelper'
import './home.scss'
import { Card } from '../../components/Page'
import { openEmail } from '../../helpers/interactionHelper'
import globals from '../../globals/globals'

const theme = {
  background: globals.colors.white,
  primary: globals.colors.primary,
  secondary: globals.colors.secondary,
  tertiary: globals.colors.tertiary,
  text: globals.colors.black
}

const Container = ({ updateIndex, setLoading, loading, index, indexMax }) => {
  const [mouse, setMouse] = useState([0, 0])

  // Mouse events
  const scrolling = useRef(false)
  const bind = useGesture({
    onWheel: ({ wheeling, delta: [, dy] }) => {
      const threshold = 10
      if (!scrolling.current) {
        if (dy > threshold) {
          // Down
          updateIndex('+')
          scrolling.current = true
        } else if (dy < -threshold) {
          // Up
          updateIndex('-')
          scrolling.current = true
        }
      } else {
        if (dy > 0 && dy < threshold) {
          // Reset from down
          scrolling.current = false
        } else if (dy < 0 && dy > -threshold) {
          // Reset from up
          scrolling.current = false
        }
      }
      // Reset if stopped wheeling
      if (!wheeling) {
        scrolling.current = false
      }
    },
    onDrag: ({ down, movement: [, my] }) => {
      if (down) return
      // Ignore if tiny movement
      if (Math.abs(my) < 10) return
      // Otherwise move up or down
      if (my < 0) {
        updateIndex('+')
      } else {
        updateIndex('-')
      }
    },
    onMove: ({ xy }) => {
      setMouse([
        (xy[0] / window.innerWidth - 0.5) * 2,
        (xy[1] / window.innerHeight - 0.5) * 2
      ])
    }
  })

  return (
    <div className='container home' {...bind()}>
      {/* Logo  */}
      <Logo
        loading={loading}
        theme={theme}
        mouse={mouse}
        onLoad={() => setLoading(false)}
      />

      {/* Content and buttons */}
      <Content
        index={index}
        theme={theme}
        onPress={() => {
          const isLast = index > indexMax - 1
          if (isLast) {
            openEmail('volt')
          } else {
            updateIndex('+')
          }
        }}
      />
    </div>
  )
}

const HomeScreen = () => {
  const [index, setIndex] = useState(0)
  const indexMax = 2
  const [loading, setLoading] = useState(true)

  const updateIndex = useCallback(
    change => {
      let newIndex = index
      if (change === '+' && index < indexMax) {
        // Next page
        newIndex = index + 1
        logEvent('next_pressed')
      } else if (change === '-' && index > 0) {
        // Prev page
        newIndex = index - 1
        logEvent('previous_pressed')
      } else if (Number.isInteger(change)) {
        // Specific index
        newIndex = change
      }
      setIndex(newIndex)
    },
    [index]
  )

  useEffect(() => {
    switch (index) {
      case 1:
        logEvent('partners')
        break
      case 2:
        logEvent('contact')
        break
      default:
        logEvent('intro')
        break
    }
  }, [index])

  // Keyboard events
  useEffect(() => {
    const onKey = e => {
      const key = e.key
      switch (key) {
        case 'Backspace':
          e.preventDefault()
          updateIndex(0)
          break
        case 'ArrowUp':
        case 'ArrowLeft':
          e.preventDefault()
          updateIndex('-')
          break
        case 'ArrowDown':
        case 'ArrowRight':
          e.preventDefault()
          updateIndex('+')
          break
        default:
          // console.log(key)
          break
      }
    }
    document.addEventListener('keydown', onKey)
    return () => {
      document.removeEventListener('keydown', onKey)
    }
  }, [updateIndex])

  // Handle when tab is switched
  // useEffect(() => {
  //   const onFocus = () => {
  //     setActive(true)
  //   }
  //   const onBlur = () => {
  //     setActive(false)
  //   }
  //   window.addEventListener('focus', onFocus)
  //   window.addEventListener('blur', onBlur)
  //   return () => {
  //     window.removeEventListener('focus', onFocus)
  //     window.removeEventListener('blur', onBlur)
  //   }
  // })

  return (
    <Card>
      {/* Background */}
      <Background loading={loading} theme={theme} />

      <Container
        updateIndex={updateIndex}
        setLoading={setLoading}
        loading={loading}
        index={index}
        indexMax={indexMax}
      />
    </Card>
  )
}

export default HomeScreen
