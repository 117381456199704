import { useCallback, useEffect, useState } from 'react'
import Svg from '../../components/Svg'
import Button from '../Button'
import RichText from '../RichText'
import { getColors, getDomain } from '../../helpers/layoutHelper'
import { openEmail } from '../../helpers/interactionHelper'
import globals from '../../globals/globals'
import './menu.scss'

const colors = [
  globals.colors.primary,
  globals.colors.secondary,
  globals.colors.tertiary
]

const Project = ({ isCv, project, title, backgroundColor, onClick }) => {
  const [active, setActive] = useState(false)

  return (
    <li
      className={active ? 'active' : null}
      onClick={onClick}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div className='line' style={{ backgroundColor }} />
      <span>
        <Svg name={isCv ? 'hand' : `logo-${project}`} />
        {title}
      </span>
    </li>
  )
}

const Projects = ({ projects, projectsData, onProject }) => {
  const projectColors = getColors(projects.length, colors)
  return (
    <ul className='projects'>
      {projects.map((project, index) => {
        const { title } = projectsData?.[project]
        const isCv = project === 'cv'
        return (
          <Project
            key={index}
            isCv={isCv}
            project={project}
            title={title}
            backgroundColor={projectColors[index]}
            onClick={() => onProject(project)}
          />
        )
      })}
    </ul>
  )
}

const Menu = ({ data, project, projectsData, onProject }) => {
  const [active, setActive] = useState(false)
  const { title, message, projects } = data
  const { title: projectTitle } = project
  const domain = getDomain()

  const onFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }, [])

  // Keyboard
  useEffect(() => {
    const onKey = e => {
      const { key } = e
      const isCombination = e.altKey || e.ctrlKey || e.metaKey || e.shiftKey
      if (isCombination) return
      switch (key) {
        case 'f':
        case 'F':
          onFullScreen()
          break
        case 'q':
        case 'Q':
        case 'k':
        case 'K':
        case 'm':
        case 'M':
          e.preventDefault()
          setActive(!active)
          break
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          const num = parseInt(key)
          if (num > projects.length) return
          onProject(projects[num - 1])
          break
        default:
          // console.log(key)
          break
      }
    }
    document.addEventListener('keydown', onKey)
    return () => {
      document.removeEventListener('keydown', onKey)
    }
  }, [active, projects, onProject, onFullScreen])

  // Launch once on load
  useEffect(() => {
    const timeout = setTimeout(() => setActive(true), 300)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className='menu'>
      {/* Overlay */}
      <div
        className={active ? 'overlay active' : 'overlay'}
        onClick={() => setActive(!active)}
      />

      {/* Menu */}
      <div className={active ? 'box active' : 'box'}>
        <div className='inner'>
          {/* Intro */}
          <div className='intro'>
            {title && <h1>{title}</h1>}
            {message && (
              <h4>
                <RichText>{message}</RichText>
              </h4>
            )}
          </div>

          {/* Projects */}
          {projects?.length > 0 && (
            <Projects
              projects={projects}
              projectsData={projectsData}
              onProject={id => {
                setActive(false)
                onProject(id)
              }}
            />
          )}

          {/* Contact button */}
          <div className='contact'>
            <Button
              title={`matt@volt.${domain}`}
              onClick={() => openEmail('matt')}
            />
          </div>
        </div>
      </div>

      {/* Button */}
      <div className='action' onClick={() => setActive(!active)}>
        <Svg name={active ? 'cross' : 'menu'} />
        {projectTitle && (
          <h4 className={!active ? 'active' : null}>{projectTitle}</h4>
        )}
      </div>
    </div>
  )
}

export default Menu
