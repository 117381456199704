import { Hero, Summary, Grid } from './templates'
import Slider from '../../components/Slider'

const FolioSpotify = () => {
  return (
    <>
      {/* Hero */}
      <Hero
        data={{
          image: null,
          video: null,
          icon: `logo-spotify`,
          title: `Spotify`,
          body: `Delivering better **contextual music experiences to fans**.`,
          background: {
            style: null,
            image: `spotify-codes.jpg`,
            video: `spotify-codes.vid`
          },
          overlay: `linear-gradient(rgba(30, 20, 100, 0), rgba(30, 20, 100, 1))`
        }}
      />

      {/* Summary */}
      <Summary
        data={{
          title: `Music for any moment`,
          body: `We became an **on-demand incubator for Spotify**, helping them to **innovate around various 'musical moments'**.`,
          cols: [
            {
              icon: `equalizer`,
              iconColor: `#00F0FF`,
              title: `Running`,
              body: `Building [the ultimate running mix](https://www.cnet.com/tech/services-and-software/hitting-the-pavement-with-spotify-running-hands-on/), by dynamically adjusting tempo / BPM to match a user's running pace.`,
              linkColor: `#00F0FF`
            },
            {
              icon: `share-ios`,
              iconColor: `#1ED760`,
              title: `Sharing`,
              body: `Making the discovery and sharing of music more universal with scannable [Spotify Codes](https://techcrunch.com/2017/05/05/spotify-codes/).`,
              linkColor: `#1ED760`
            },
            {
              icon: `people-3`,
              iconColor: `#FFD600`,
              title: `Party`,
              body: `Exploring ways to make music streaming more of an engaging, multiplayer experience.`,
              linkColor: `#FFD600`
            },
            {
              icon: `artist`,
              iconColor: `#FF4BA2`,
              title: `Live`,
              body: `Imagining what a live festival experience could be like for Spotify's music fans.`,
              linkColor: `#FF4BA2`
            }
          ]
        }}
      />

      {/* Slider */}
      <Slider
        data={{
          slides: [
            {
              title: `Running`,
              image: `spotify-mobile-running.png`
            },
            {
              title: `Sharing`,
              image: `spotify-mobile-codes-1.png`
            },
            {
              image: `spotify-mobile-codes-2.png`
            },
            {
              image: `spotify-mobile-codes-3.png`
            },
            {
              title: `Party`,
              image: `spotify-mobile-party.png`
            },
            {
              title: `Live`,
              image: `spotify-mobile-live.png`
            }
          ],
          background: {
            style: `linear-gradient(rgba(251, 34, 255, 0.2), rgba(251, 34, 255, 0))`,
            image: null,
            video: null
          },
          invert: false
        }}
      />

      {/* Grid */}
      <Grid
        data={{
          items: [
            {
              image: `spotify-grid-sharing.png`,
              title: `Sharing Personality`,
              body: `We also looked at ways to make sharing more human and personal, giving users the ability to doodle, stamp and their artefacts.`,
              backgroundColor: `#0400B8`,
              textColor: `#FFFFFF`
            },
            {
              image: `spotify-grid-running.png`,
              title: `Rhythm + Research`,
              body: `We worked with R&D teams in Gothenburg and Stockholm to develop beat matching and scannable code technology.`,
              backgroundColor: `#080039`,
              textColor: `#FFFFFF`
            },
            {
              image: `spotify-grid-codemaking.png`,
              title: `Before, during, after`,
              body: `We helped draft and define intial ideas, prototype and validate designs, and evolve propositions post-launch with A/B testing and tooling.`,
              backgroundColor: `#209663`,
              textColor: `#FFFFFF`
            }
          ]
        }}
      />
    </>
  )
}

export default FolioSpotify
